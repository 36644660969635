import axios from 'axios'

import { DASHBOARD, HOME } from './actions'

import { DashboardService } from '@/common/api_services/dashboard'
import { HomeService } from '@/common/api_services/home'
import { apiBase } from '@/conf'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'

const state = {
    activity: {},
    overview: {},
    charts_info: {},
    pieChart: {},
    percentage: {},
    footer: [],
    homeInfo: [],
}

const getters = {
    activity: (state) => state.activity,
    overview: (state) => state.overview,
    charts: (state) => state.charts_info,
    percentage: (state) => state.percentage,
    footerInfo: (state) => state.footer,
    homeInfo: (state) => state.homeInfo,
    pieChart: (state) => state.pieChart,
}

const mutations = {
    [DASHBOARD.SET_ACTIVITY]: (state, resp) => {
        state.activity = resp.data
    },
    [DASHBOARD.SET_CHARTS]: (state, resp) => {
        state.charts_info = resp.data
    },
    [DASHBOARD.SET_PERCENTAGE]: (state, resp) => {
        state.percentage = resp.data
    },
    [DASHBOARD.SET_PIE_CHART_DATA]: (state, resp) => {
        state.pieChart = resp.data
    },
    [HOME.SET_FOOTER]: (state, resp) => {
        state.footer = resp.data
    },
    [HOME.SET_HOME_INFO]: (state, resp) => {
        state.homeInfo = resp.data
    },
    [HOME.SET_HOME_INFO_ADMIN]: (state, resp) => {
        state.homeInfo = resp.data
    },
    [HOME.SET_STORE_NEW_DETAILS]: (state, resp) => {
        state.homeInfo = resp
    },
}

const actions = {
    [DASHBOARD.ACTIVITY]: ({ commit }, payload) => {
        return DashboardService.getActivity(payload)
            .then((resp) => {
                commit(DASHBOARD.SET_ACTIVITY, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [DASHBOARD.OVERVIEW]: ({ commit }, payload) => {
        return DashboardService.getOverview(payload)
            .then((resp) => {
                commit(DASHBOARD.OVERVIEW, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [DASHBOARD.CHARTS]: ({ commit }, payload) => {
        return DashboardService.getChartsInfo(payload)
            .then((resp) => {
                commit(DASHBOARD.SET_CHARTS, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [DASHBOARD.PERCENTAGE]: ({ commit }, payload) => {
        return DashboardService.getPercentage(payload)
            .then((resp) => {
                commit(DASHBOARD.SET_PERCENTAGE, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [DASHBOARD.GET_PIE_CHART_DATA]: ({ commit }, payload) => {
        return DashboardService.getPieChartData(payload)
            .then((resp) => {
                commit(DASHBOARD.SET_PIE_CHART_DATA, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [HOME.GET_FOOTER]: ({ commit }, payload) => {
        return HomeService.getFooter(payload)
            .then((resp) => {
                commit(HOME.SET_FOOTER, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [HOME.GET_HOME_INFO]: ({ commit }, payload) => {
        return HomeService.getHomeDetails(payload)
            .then((resp) => {
                commit(HOME.SET_HOME_INFO, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [HOME.GET_HOME_INFO_ADMIN]: ({ commit }, payload) => {
        commit(FETCH_START)
        return HomeService.getHomeDetailsForAdmin(payload)
            .then((resp) => {
                commit(HOME.SET_HOME_INFO_ADMIN, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [HOME.STORE_NEW_DETAILS]: ({ commit }, payload) => {
        commit(HOME.SET_STORE_NEW_DETAILS, payload)
    },
    [HOME.EDIT_DETAILS]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .patch(apiBase() + 'dashboard-homepage/1/', payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [HOME.EDIT_FOOTER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .patch(apiBase() + 'footer/1/', payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                commit(HOME.SET_FOOTER, resp)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [HOME.CREATE_SOCIAL]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .post(apiBase() + 'footer/', payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                commit(HOME.SET_FOOTER, resp)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [HOME.EDIT_SERVICE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return axios
            .post(apiBase() + 'dashboard-homepage/', payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [HOME.DELETE_SERVICE]: ({ commit, dispatch }, payload) => {
        commit(FETCH_START)
        return HomeService.deleteService(payload)
            .then((resp) => {
                dispatch(HOME.GET_HOME_INFO_ADMIN)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [HOME.DELETE_SOCAIL_LINK]: ({ commit, dispatch }, payload) => {
        commit(FETCH_START)
        return HomeService.deleteSocialLink(payload)
            .then((resp) => {
                dispatch(HOME.GET_FOOTER)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
