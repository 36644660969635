import { ApiService } from '@/common/api_services'

export const HomeService = {
    getFooter(params) {
        return ApiService.query('footer-detail/1/', { params: params })
    },
    getHomeDetails(params) {
        return ApiService.query('dashboard-homepage-detail/1/', {
            params: params,
        })
    },
    getHomeDetailsForAdmin(params) {
        return ApiService.query('dashboard-homepage/', { params: params })
    },
    deleteService(id) {
        return ApiService.delete(`service-delete/${id}/`)
    },
    deleteSocialLink(id) {
        return ApiService.delete(`social-links-delete/${id}/`)
    },
}
