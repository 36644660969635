<template>
    <div style="position: relative">
        <div class="form-group" v-show="!uploaded && !readonlyInput">
            <label class="my-2" v-if="label">
                <!-- <span style="width: 500px;" class="text-dark">{{ name }}</span> -->
                <span
                    :style="[
                        setWidthName
                            ? { width: '670px!important' }
                            : { width: '500px!important' },
                    ]"
                    class="text-dark custom-name-not-uploaded"
                    >{{ label }}
                    <span class="text-danger my-2" v-if="required"
                        >&#42;</span
                    ></span
                >
            </label>
            <div class="upload-file">
                <input
                    type="file"
                    name="file"
                    id="file-input"
                    @change="uploadFile"
                    :accept="accept"
                    ref="refFile"
                    :multiple="multiple"
                />
                {{ button_text || $t('general.Upload') }}
            </div>
            <br />
            <small class="text-danger mx-2" v-if="required"
                >( {{ getFileAccept(accept) }} File )</small
            >
            <span class="vertical-line" v-if="!end"></span>
            <validation-provider
                ref="provider"
                :name="name"
                :rules="required ? 'required' : ''"
                v-slot="{ errors }"
            >
                <input type="hidden" :value="url" />
                <span class="error" v-if="errors.length">{{
                    $t(
                        `general.${
                            errors[0][errors[0].length - 1] === '.'
                                ? errors[0].slice(0, -1)
                                : errors[0]
                        }`
                    )
                }}</span>
            </validation-provider>
        </div>
        <div class="form-group success" v-show="uploaded">
            <label class="my-2">
                <span
                    :style="[
                        setWidthName
                            ? { width: '570px!important' }
                            : { width: '400px!important' },
                    ]"
                    class="text-dark custom-name-uploaded"
                    v-if="label"
                    >{{ label }}
                    <span class="text-danger my-2" v-if="required">
                        &#42;</span
                    ></span
                >
            </label>
            <div class="inline-buttons">
                <a class="btn btn-primary view" @click="view">
                    {{ $t('general.View') }}
                </a>
                <a
                    v-if="!readonlyInput && !modify"
                    class="btn btn-danger delete"
                    @click="remove"
                >
                    {{ $t('general.Delete') }}
                </a>
                <a
                    class="btn btn-dark view"
                    v-else-if="!readonlyInput && modify"
                    @click="replace"
                >
                    {{ $t('general.Replace') }}
                </a>
            </div>
            <br />
            <small class="text-danger my-2" v-if="required"
                >(
                <strong>Only {{ getFileAccept(accept) }} Files</strong> )</small
            >
            <span class="vertical-line" v-if="!end"></span>
        </div>
        <div class="form-group" v-show="!uploaded && readonlyInput">
            <label class="my-2">
                <span
                    class="text-dark custom-name-uploaded"
                    :style="[
                        setWidthName
                            ? { width: '570px!important' }
                            : { width: '400px!important' },
                    ]"
                    v-if="label"
                    >{{ label }} <span class="text-danger">&#42;</span></span
                >
                <br />
                <small v-if="required"
                    >(
                    <strong>Only {{ getFileAccept(accept) }} Files</strong>
                    )</small
                >
            </label>
            <span class="vertical-line" v-if="!end"></span>
        </div>
    </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty'

import { modifyInputs } from '@/common/mixins/modifyInputs.js'
export default {
    mixins: [modifyInputs],
    props: {
        name: {
            type: String,
        },
        note: {
            type: String,
        },
        button_text: {
            type: String,
        },
        end: {
            type: Boolean,
        },
        /**
         * accepted files (pdf, txt ..etc)
         */
        accept: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        readonly: {
            type: Boolean,
        },
        /** extra info to pass back to upload method */
        extra: {
            type: Object,
            required: false,
            default: () => {},
        },
        setErrors: {
            type: Array,
            required: false,
            default: () => [],
        },
        setWidthName: {
            type: Boolean,
            default: false,
        },
        fileURL: String,
        index: Number,
        id: Number,
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
    },
    data() {
        return {
            file: null,
            uploaded: false,
            url: '',
        }
    },
    created() {
        this.setURL()
    },
    watch: {
        setErrors: function (newVal) {
            this.$refs.provider.setErrors(newVal)
            if (!isEmpty(newVal)) {
                this.uploaded = false
            }
        },
        fileURL() {
            this.setURL()
        },
    },
    methods: {
        getFileAccept(str) {
            return str.split(',')[0] + ' / ' + str.split(',')[1]
        },
        setURL() {
            if (this.fileURL) {
                this.uploaded = true
                this.url = this.fileURL
            } else {
                this.uploaded = false
                this.url = ''
            }
        },
        uploadFile(event) {
            this.$refs.provider.reset()
            this.file = event.target.files[0]
            if (typeof this.file !== 'undefined') {
                this.uploaded = true
                this.url = URL.createObjectURL(this.file)
                this.$emit('upload', {
                    url: this.url,
                    file: this.file,
                    id: this.id,
                })
            }
        },
        view() {
            window.open(this.url)
        },
        replace() {
            this.$refs.refFile.value = null
            this.file = null
            this.uploaded = false
        },
        remove() {
            this.$refs.refFile.value = null
            this.file = null
            this.uploaded = false
            this.$emit('remove')
        },
    },
}
</script>
<style scoped>
.upload-file {
    width: fit-content;
    padding: 0 30px;
}
.error {
    display: block;
    padding-left: 40px;
    padding-top: 10px;
}
.upload-file {
    position: relative;
    cursor: pointer;
    width: 150px;
    height: 55px;
    line-height: 55px;
    font-weight: 700;
    background: #fff;
    border-radius: 5px;
    border: 2px dashed #b7b7b7;
    color: #298fc2;
    text-align: center;
    text-transform: uppercase;
    margin-left: 3.125rem;
    transition: 0.3s all ease-in-out;
}
.upload-file:hover,
.upload-file:focus {
    background: #d6f0fd;
    color: #298fc2;
    box-shadow: none !important;
}
</style>
