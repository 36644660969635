export const VENDORS = {
    VENDORS_LIST: 'getVendorsList',
    SET_VENDORS_LIST: 'setVendorsList',
    SHOW_ROW: 'showVendor',
    SET_VISIBILITY_ROW: 'setVisibilityVendor',
    SHOW_INSTALLMENT_ROW: 'showVendorInstallment',
    SET_INSTALLMENT_VISIBILITY_ROW: 'setVisibilityInstallment',
    INSTALLMENTS_LIST: 'getInstallmentsList',
    SET_INSTALLMENTS_LIST: 'setInstallmentsList',
    SET_VENDOR_PROFILE: 'setVendorProfile',
    VENDOR_PROFILE_DETAILS: 'getVendorProfileDetails',
    UPDATE_VENDOR_PROFILE: 'updateVendorProfile',
    DELETE_VENDOR_PROFILE: 'deleteVendorProfile',
    APPLICATIONS_LIST: 'getApplicationsList',
    SET_APPLICATIONS_LIST: 'setApplicationsList',
    APPROVE_VENDOR: 'approveVendor',
}
