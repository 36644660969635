import { ApiService } from '@/common/api_services'

export const DashboardService = {
    getActivity(params) {
        return ApiService.query('activity-log/', { params: params })
    },
    getOverview(params) {
        return ApiService.query('overview', { params })
    },
    getChartsInfo(params) {
        return ApiService.query('dashboard-order-chart-list/', {
            params: params,
        })
    },
    getPercentage(params) {
        return ApiService.query('dashboard-order-percentage/', {
            params: params,
        })
    },
    getPieChartData(params) {
        return ApiService.query('top-five-vendors/', {
            params: params,
        })
    },
}
