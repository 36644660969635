import axios from 'axios'

import { USERS } from './actions'

import { UserService } from '@/common/api_services/user'
import cookieHandler from '@/common/cookies-service'
import { i18n } from '@/common/i18n'
import TableHandler from '@/common/table-service'
import { apiBase } from '@/conf'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'
const state = {
    userAuth: {},
    usersList: [],
    user: {},
}

const getters = {
    userAuth: (state) => state.userAuth,
    usersList: (state) => state.usersList,
    user: (state) => state.user,
}

const mutations = {
    [USERS.SET_AUTH_USER]: (state, resp) => {
        state.userAuth = resp.data
        resp.data.is_vendor
            ? (state.userAuth.type = i18n.t('general.vendor'))
            : (state.userAuth.type = i18n.t('general.admin'))
        state.userAuth.full_name =
            resp.data.first_name + ' ' + resp.data.last_name
    },
    [USERS.SET_LIST]: (state, resp) => {
        state.usersList = TableHandler.setUserTable(resp.data)
    },
    [USERS.SET_USER]: (state, resp) => {
        state.user = resp.data
    },
    [USERS.SET_VISIBILITY_ROW]: (state, resp) => {
        let target = state.usersList.results.find((el) => el.id === resp)
        target['_showDetails'] = !target['_showDetails']
    },
    [USERS.USER_AUTH_LOGOUT]: (state) => {
        state.userAuth = null
    },
}
const actions = {
    [USERS.GET_AUTH_USER]: ({ commit }) => {
        commit(FETCH_START)
        axios
            .get(apiBase() + 'details/')
            .then((resp) => {
                cookieHandler.setCookie('is_superuser', resp.data.is_superuser)
                commit(USERS.SET_AUTH_USER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                cookieHandler.deleteCookie('is_superuser')
                throw err
            })
    },
    [USERS.LIST]: ({ commit }, payload) => {
        return UserService.query(payload)
            .then((resp) => {
                commit(USERS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.LIST_VENDORS]: ({ commit }, payload) => {
        return UserService.getVendors(payload)
            .then((resp) => {
                commit(USERS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.VENDOR_CUSTOMERS_LIST]: ({ commit }, payload) => {
        return UserService.vendorCustomersList(payload.id, payload.filter)
            .then((resp) => {
                commit(USERS.SET_LIST, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.GET_USER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UserService.get(payload)
            .then((resp) => {
                commit(USERS.SET_USER, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },

    [USERS.UPDATE_USER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UserService.update(payload.id, payload.user)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.UPDATE_AUTH_USER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UserService.editAuthUser(payload.data)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                commit(USERS.SET_AUTH_USER, resp)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.SHOW_ROW]: ({ commit }, payload) => {
        commit(USERS.SET_VISIBILITY_ROW, payload)
    },
    [USERS.CREATE]: ({ commit, dispatch }, payload) => {
        commit(FETCH_START)
        return UserService.create(payload)
            .then((resp) => {
                if (payload.isVendor) {
                    dispatch(USERS.LIST_VENDORS)
                } else {
                    dispatch(USERS.LIST)
                }
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USERS.SEARCH]: ({ commit }, payload) => {
        return UserService.search(payload)
            .then((resp) => {
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
