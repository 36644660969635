import { ApiService } from '@/common/api_services'

export const VendorsService = {
    approveVendor(id, params) {
        return ApiService.put(`applications/${id}/`, params)
    },
    getApplicationsList(params) {
        return ApiService.query('applications/', { params: params })
    },
    getVendorsList(params) {
        return ApiService.query('vendors-create/', { params: params })
    },
    getInstallmentsList(id, params) {
        return ApiService.query(`vendor-installments/${id}/`, {
            params: params,
        })
    },
    updateVendorProfile(pk, params) {
        return ApiService.update('users', pk, params)
    },
    getVendorProfileDetails(pk) {
        return ApiService.get('users', pk)
    },
    deleteVendorProfile(pk) {
        return ApiService.delete(`users/${pk}/`)
    },
}
