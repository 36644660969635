const API_BASE =
    process.env.VUE_APP_BUYTECH_API_BASE || 'http://localhost:8080/backend/'
const VUE_APP_WS_NOTIFICATIONS = process.env.VUE_APP_WS_NOTIFICATIONS
const VUE_APP_WS_CHAT = process.env.VUE_APP_WS_CHAT
const VUE_APP_WS_SENTRY = process.env.VUE_APP_WS_SENTRY
import { i18n } from '@/common/i18n'

let apiBase = function () {
    return API_BASE + `${i18n.locale}/api/v1/`
    // return API_BASE
}
export {
    apiBase,
    API_BASE,
    VUE_APP_WS_NOTIFICATIONS,
    VUE_APP_WS_CHAT,
    VUE_APP_WS_SENTRY,
}
