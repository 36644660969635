import { ApiService } from '@/common/api_services'

export const CatalogueService = {
    query(params) {
        return ApiService.query('shop/products/', { params })
    },
    get(pk) {
        return ApiService.get('shop/products/', pk)
    },
    create(params) {
        return ApiService.post('dashboard-products/', params)
    },
    getProduct(params) {
        return ApiService.query('dashboard-products/', { params })
    },
}
