import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/sccs/utility/_variables.scss'
import 'vue2-datepicker/index.css'
import '@/plugins/vee-validate.js'
import '@/plugins/usd-format.js'

// import VSelect from '@alfsnd/vue-bootstrap-select'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import jQuery from 'jquery'
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import DatePicker from 'vue2-datepicker'
import './registerServiceWorker'
window.jQuery = window.$ = jQuery

Vue.component('date-picker', DatePicker)

import App from './App.vue'
import router from './router'

import SvgIcon from '@/common/components/base/BaseSvgIcon.vue'
import cookieHandler from '@/common/cookies-service'
import { i18n } from '@/common/i18n'
import store from '@/store'
import '@/common/components/global'
import '@/common/prototypes'
library.add(fas)
library.add(fab)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LoadScript)

Vue.component('svg-icon', SvgIcon)
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.component('VSelect', VSelect)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    // Remove OTP Token If user aren't on the reset password page
    if (to.name !== 'reset_password')
        localStorage.removeItem('reset-password-token')
    // Setup local language
    let lang = to.params.lang ? to.params.lang : 'en'
    if (
        (cookieHandler.getCookie('locale') &&
            cookieHandler.getCookie('locale') !== lang) ||
        (!cookieHandler.getCookie('locale') && lang !== 'en')
    ) {
        cookieHandler.setCookie('locale', lang)
        window.location.reload()
    }
    cookieHandler.setCookie('locale', lang)
    i18n.locale = lang
    if (to.fullPath === `/${lang}` || to.fullPath === '/') {
        next({
            name: 'home_index',
            params: {
                lang: lang,
            },
        })
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next({
                name: 'home_index',
                params: {
                    lang: lang,
                },
            })
        } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
            if (cookieHandler.getCookie('is_superuser') === 'false') {
                next({
                    name: 'dashboard_index',
                    params: {
                        lang: lang,
                    },
                })
            } else {
                next()
            }
        } else {
            next()
        }
    } else if (to.matched.some((record) => record.meta.requiresGuest)) {
        if (store.getters.isAuthenticated) {
            next({
                name: 'dashboard_index',
                params: {
                    lang: lang,
                },
            })
        } else {
            next()
        }
    } else {
        next()
    }
})
new Vue({
    router,
    store,
    created() {},
    i18n,
    render: (h) => h(App),
}).$mount('#app')
