export default [
    {
        path: '/:lang',
        meta: {
            requiresAuth: true,
            title: 'Orders page',
        },
        redirect: { name: 'orders_index' },
        name: 'orders',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/orders',
                meta: {
                    title: 'Orders',
                },
                props: true,
                name: 'orders_index',
                component: () => import('@/modules/orders/views/Orders.vue'),
            },
            {
                path: '/:lang/order/create',
                meta: {
                    title: 'Create New Order',
                },
                name: 'create_order',
                props: true,
                component: () =>
                    import('@/modules/orders/views/CreateOrder.vue'),
            },
            {
                path: '/:lang/orders/:id',
                meta: {
                    title: 'View Order',
                },
                name: 'order_details',
                component: () =>
                    import('@/modules/orders/components/OrderDetails.vue'),
            },
            {
                path: '/:lang/confirmation',
                meta: {
                    title: 'Confirmation Order Page',
                },
                name: 'order_confirmation',
                component: () =>
                    import('@/modules/orders/components/OrderConfirmation.vue'),
            },
            {
                path: ':id/pending-confirmation',
                meta: {
                    title: 'Pending-confirmation Order Page',
                },
                name: 'order_pending_confirmation',
                component: () =>
                    import(
                        '@/modules/orders/components/OrderPendingConfirmation.vue'
                    ),
            },
        ],
    },
]
