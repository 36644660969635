<template>
    <div class="activity-card">
        <div
            class="activity-card__item"
            v-for="(item, index) in data"
            :key="index + 1"
        >
            <div class="activity-card__item--icon">
                <img
                    class="img-fluid"
                    :src="require(`@/assets/images/svg/${item.img}`)"
                    alt=""
                />
            </div>
            <p>
                <span>{{ item.numbers }}</span>
                {{ item.title }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
        isLabel: Boolean,
    },
}
</script>

<style lang="scss" scoped>
.activity-card {
    background: #f7faff;
    border-radius: 25px;
    padding: rem(20px);
    &__item {
        display: flex;
        align-items: center;
        &--icon {
            background: #eaf9ff;
            width: rem(90px);
            height: rem(82px);
            border-radius: 15px;
            @include flex(center, center);
            margin-right: rem(25px);
            padding: rem(17px);
        }
        p {
            font-size: rem(16px);
            font-weight: 500;
            color: #7c8db5;
            font-family: $reboto-font;
            line-height: 1;
            margin: 0;
            span {
                display: block;
                font-size: rem(20px);
                font-weight: 700;
                margin-bottom: 13px;
                color: var(--primary);
                font-family: $primary-font;
            }
        }
    }
    @media screen and (max-width: 991px) {
        border-radius: 20px;
        padding: rem(15px);
        &__item {
            &--icon {
                margin-right: rem(15px);
                padding: rem(15px);
                width: rem(80px);
                height: rem(72px);
            }
            p {
                span {
                    font-size: rem(18px);
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: rem(16px) rem(12px);
        &__item {
            flex-direction: column;
            &--icon {
                margin: auto;
                margin-bottom: 6px;
                width: 50px;
                height: 47px;
            }
            p {
                text-align: center;
                span {
                    margin-bottom: 7px;
                }
            }
        }
    }
    @media screen and (max-width: 575px) {
        padding: rem(16px) rem(8px);
        &__item {
            &--icon {
                width: 45px;
                height: 42px;
            }
            p {
                font-size: rem(13px);
            }
        }
    }
}

[lang='ar'] {
    .activity-card {
        &__item {
            &--icon {
                margin-left: rem(25px);
                margin-right: 0;
            }
        }
        @media screen and (max-width: 991px) {
            &__item {
                &--icon {
                    margin-left: rem(15px);
                }
            }
        }
    }
}
</style>
