import { ApiService } from '@/common/api_services'

export const TransactionsService = {
    getVendorTransactionsList(id, params) {
        return ApiService.query(`vendor-sales/${id}/`, {
            params: params,
        })
    },
    getTransactionsList(params) {
        return ApiService.query('transactions/', { params: params })
    },
    getTransactionsReport(params) {
        return ApiService.query('report/', { params: params })
    },
}
