import { parsePhoneNumber } from 'awesome-phonenumber'

const IraqiPhoneNumberHandler = {
    IraqiMobileNumber(mobile) {
        if (!mobile) return mobile
        const phone = parsePhoneNumber(mobile, 'IQ')
        if (phone.getCountryCode() === 964) return phone.isValid()
    },
}

export default IraqiPhoneNumberHandler
