const PasswordHandler = {
    isComplexPassword(password) {
        if (!password) {
            return false
        } else {
            return (
                /[A-Z]/.test(password) &&
                /[a-z]/.test(password) &&
                /\d/.test(password) &&
                /[!@#$%^&*()_\-+=`~<>?\\/;,.'"\][{}]/.test(password)
            )
        }
    },
}

export default PasswordHandler
