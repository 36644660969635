export default [
    {
        path: '/:lang',
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            title: 'Vendors page',
        },
        redirect: { name: 'vendor_index' },
        name: 'vendor',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/vendors',
                meta: {
                    title: 'View Vendors',
                },
                name: 'vendor_index',
                component: () => import('@/modules/vendors/views/Vendors.vue'),
            },
            {
                path: '/:lang/installments/:id',
                meta: {
                    title: 'View Installments',
                },
                name: 'vendor_installments',
                component: () =>
                    import('@/modules/vendors/views/Installments.vue'),
            },
            {
                path: '/:lang/sale-reports/:id',
                meta: {
                    title: 'View Installments',
                },
                name: 'vendor_sale_reports',
                component: () =>
                    import('@/modules/vendors/views/SaleReports.vue'),
            },
            {
                path: '/:lang/vendor-profile/:id',
                meta: {
                    title: 'View Vendor Profile',
                },
                name: 'vendor_profile',
                component: () =>
                    import('@/modules/vendors/components/VendorsProfile.vue'),
            },
            {
                path: '/:lang/vendor-dashboard/:id',
                meta: {
                    title: 'Vendor Dashboard',
                },
                name: 'vendor_dashboard',
                component: () =>
                    import('@/modules/vendors/views/VendorsDashboard.vue'),
            },
            {
                path: '/:lang/vendor-customers/:id',
                meta: {
                    title: 'Vendor Customer',
                },
                name: 'vendor_customers',
                component: () =>
                    import('@/modules/vendors/views/VendorsCustomers.vue'),
            },
            {
                path: '/:lang/vendor-orders/:id',
                meta: {
                    title: 'Vendor Order',
                },
                name: 'vendor_orders',
                component: () =>
                    import('@/modules/vendors/views/VendorsOrders.vue'),
            },
        ],
    },
    {
        path: '/:lang/vendor-applications',
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
            title: 'Vendor Applications',
        },
        name: 'vendor_applications',
        component: () =>
            import('@/modules/vendors/views/VendorsApplications.vue'),
    },
]
