export default [
    {
        path: '/',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/login',
                name: 'login',
                meta: {
                    requiresGuest: true,
                },
                component: () => import('../views/Login'),
            },
            {
                path: '/:lang/signup',
                name: 'signup',
                meta: {
                    requiresGuest: true,
                },
                component: () => import('../views/SignUp'),
            },
            {
                path: '/:lang/forgot-password',
                name: 'forgot_password',
                meta: {
                    requiresGuest: true,
                },
                component: () => import('../views/ForgetPassword'),
            },
            {
                path: ':lang/reset-password/:token',
                name: 'reset_password',
                meta: {
                    requiresGuest: true,
                },
                component: () => import('../views/ResetPassword'),
            },
        ],
    },
]
