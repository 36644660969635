import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'

import { apiBase } from '@/conf'

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = apiBase()

export const ApiService = {
    init() {},

    query(resource, params) {
        return Vue.axios.get(resource, params)
    },

    get(resource, pk = '') {
        return Vue.axios.get(`${resource}/${pk}/`)
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params)
    },

    file(resource, params) {
        let formData = new FormData()
        formData.append('file', params.file)
        return Vue.axios.post(`${resource}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    update(resource, pk, params) {
        return Vue.axios.patch(`${resource}/${pk}/`, params)
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params)
    },

    delete(resource) {
        return Vue.axios.delete(resource)
    },
}

export default ApiService
