import axios from 'axios'
import Vue from 'vue'

import {
    AUTH_SIGNUP,
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    REFRESH_TOKEN,
    SET_TOKENS,
} from './actions'

import { UserService } from '@/common/api_services/user'
import cookieHandler from '@/common/cookies-service'
import { apiBase } from '@/conf'
import { USER, USERS } from '@/modules/users/store/actions'
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '@/store/common'
const state = {
    token: cookieHandler.getCookie('user-token') || '',
    status: '',
    refreshToken: cookieHandler.getCookie('user-refresh-token') || '',
}

const getters = {
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
}

const actions = {
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            axios
                .post(apiBase() + 'login/', user)
                .then((resp) => {
                    commit(AUTH_SUCCESS, resp)
                    let token = cookieHandler.getCookie('user-token')
                    cookieHandler.setCookie('user-token', resp.data.access)
                    cookieHandler.setCookie(
                        'user-refresh-token',
                        resp.data.refresh
                    )
                    axios.defaults.headers.common['Authorization'] =
                        'Bearer ' + token
                    dispatch(USERS.GET_AUTH_USER)
                    resolve(resp)
                })
                .catch((err) => {
                    commit(AUTH_ERROR)
                    axios.defaults.headers.common['Authorization'] = ''
                    cookieHandler.deleteCookie('user-token')
                    cookieHandler.deleteCookie('user-refresh-token')
                    reject(err)
                })
        })
    },
    [AUTH_SIGNUP]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit(FETCH_START)
            axios
                .post(apiBase() + 'applications-create/', user)
                .then((resp) => {
                    commit(FETCH_SUCCESS)
                    resolve(resp)
                })
                .catch((err) => {
                    commit(FETCH_ERROR)
                    reject(err)
                })
        })
    },
    [REFRESH_TOKEN]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            let refreshObj = {
                refresh: cookieHandler.getCookie('user-refresh-token'),
            }
            axios
                .post(apiBase() + 'login/refresh/', refreshObj)
                .then((resp) => {
                    commit(SET_TOKENS, resp)
                    cookieHandler.setCookie('user-token', resp.data.access)
                    if (resp.data.refresh)
                        cookieHandler.setCookie(
                            'user-refresh-token',
                            resp.data.refresh
                        )
                    resolve(resp)
                })
                .catch((err) => {
                    if (
                        err.response.data?.detail?.includes('expired') ||
                        err.response.data?.detail?.includes('invalid') ||
                        err.response.status === 401 ||
                        err.response.status === 403
                    ) {
                        axios.defaults.headers.common['Authorization'] = ''
                        cookieHandler.deleteCookie('user-token')
                        cookieHandler.deleteCookie('user-refresh-token')
                    }
                    reject(err)
                })
        })
    },
    [USER.GENERATE_OTP]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UserService.generateOtp(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USER.VERIFY_OTP]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UserService.verifyOtp(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [USER.RESET_PASSWORD]: ({ commit }, payload) => {
        commit(FETCH_START)
        return UserService.resetPassword(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(USERS.USER_AUTH_LOGOUT)
            commit(AUTH_LOGOUT)
            axios.defaults.headers.common['Authorization'] = ''
            cookieHandler.deleteCookie('user-token')
            cookieHandler.deleteCookie('user-refresh-token')
            cookieHandler.deleteCookie('is_superuser')
            Vue.prototype.$socket.close()
            resolve()
        })
    },
}

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.refreshToken = resp.data.refresh
        state.token = resp.data.access
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error'
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = ''
        state.refreshToken = ''
        state.isAuthenticated = false
    },
    [SET_TOKENS]: (state, resp) => {
        state.token = resp.data.access
        if (resp.data.refresh) state.refreshToken = resp.data.refresh
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
