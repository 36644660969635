export default [
    {
        path: '/:lang',
        redirect: { name: 'dashboard_index' },
        name: 'dashboard',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/dashboard/',
                meta: {
                    requiresAuth: true,
                    title: 'Dashboard page',
                },
                name: 'dashboard_index',
                component: () =>
                    import('@/modules/dashboard/views/Dashboard.vue'),
            },
            {
                path: '/:lang/home/edit',
                meta: {
                    requiresAdmin: true,
                    requiresAuth: true,
                    title: 'Home page | Edit',
                },
                name: 'home_edit',
                component: () =>
                    import('@/modules/dashboard/components/home/cms/Edit.vue'),
            },
            {
                path: '/:lang/home/services',
                meta: {
                    requiresAdmin: true,
                    requiresAuth: true,
                    title: 'Home page | Edit Services',
                },
                name: 'home_services',
                props: true,
                component: () =>
                    import(
                        '@/modules/dashboard/components/home/cms/Services.vue'
                    ),
            },
            {
                path: '/:lang/home/important-links',
                meta: {
                    requiresAdmin: true,
                    requiresAuth: true,
                    title: 'Home page | Important Links',
                },
                name: 'footer_links',
                props: true,
                component: () =>
                    import(
                        '@/modules/dashboard/components/home/cms/ImportantLinks.vue'
                    ),
            },
            {
                path: '/:lang/home/social-media',
                meta: {
                    requiresAdmin: true,
                    requiresAuth: true,
                    title: 'Home page | Social Media',
                },
                name: 'footer_social',
                props: true,
                component: () =>
                    import(
                        '@/modules/dashboard/components/home/cms/SocialMedia.vue'
                    ),
            },
        ],
    },
]
