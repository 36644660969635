import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'

import { i18n } from '@/common/i18n'
import iraqiPhoneNumberReg from '@/common/iraqi-phone-number'
import passwordReg from '@/common/password-service'

// vee validate rules
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule])
})

extend('required', {
    ...rules.required,
    message: i18n.t('general.This field is required'),
})
extend('number', {
    ...rules.integer,
    message: i18n.t('general.This field must be a number'),
})

extend('email', {
    message: i18n.t('general.Email is not valid'),
})

extend('password', {
    message: i18n.t(
        'general.Password must contain 1 uppercase, 1 lowercase, 1 number, and 1 special character'
    ),
    validate: (value) => {
        return passwordReg.isComplexPassword(value)
    },
})

extend('confirmed', {
    message: i18n.t('general.Passwords do not match'),
})

extend('confirmedEmail', {
    message: i18n.t('general.Emails do not match'),
    validate: (confirmEmail, email) => {
        return confirmEmail === email[0]
    },
})

extend('phone', {
    message: i18n.t('general.Invalid Iraqi Phone Number'),
    validate: (value) => {
        return iraqiPhoneNumberReg.IraqiMobileNumber(value)
    },
})

extend('min', {
    message: i18n.t('general.The input is too short'),
})
extend('max', {
    message: i18n.t('general.The input is too long'),
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
