export default [
    {
        path: '/:lang',
        meta: {
            requiresAuth: true,
            title: 'Transactions page',
        },
        redirect: { name: 'transaction_index' },
        name: 'transaction',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/transactions',
                name: 'transaction_index',
                component: () =>
                    import('@/modules/transactions/views/Transactions.vue'),
            },
        ],
    },
]
