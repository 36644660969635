var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('validation-observer',[_c('div',[_c('validation-provider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed }){return [_c('input',{staticClass:"form-control",class:[
                            errors.length && !_vm.bordered
                                ? `error-border-bottom`
                                : errors.length && _vm.bordered
                                ? `error-border`
                                : _vm.className,
                            passed && !_vm.readonly && !_vm.bordered
                                ? `success-border-bottom`
                                : passed && _vm.bordered
                                ? `success-border`
                                : _vm.className,
                            _vm.readonly ? `readonly` : _vm.className,
                        ],attrs:{"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"invalid-feedback",class:_vm.absolute ? 'position-absolute' : ''},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }