<template>
    <div>
        <div id="chart">
            <VueApexCharts
                ref="chart"
                type="bar"
                height="400"
                :options="option"
                :series="series"
            ></VueApexCharts>
            <ul class="chart-arrows">
                <li>
                    <a href="#">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            fill="currentColor"
                        >
                            <path
                                d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
                            />
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            fill="currentColor"
                        >
                            <path
                                d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"
                            />
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    props: {
        chartData: Object,
        color: {
            default: '#5BCDF8',
            type: String,
        },
        name: String,
    },
    components: {
        VueApexCharts,
    },
    data() {
        return {
            series: [
                {
                    name: this.name,
                    data: [5, 9, 2, 9, 9, 5, 2],
                },
            ],
            option: {
                chart: {
                    height: 400,
                    type: 'bar',
                    fontFamily: 'Roboto, sans-serif',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 9,
                        columnWidth: '20px',
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },
                },
                colors: [this.color],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '0',
                        colors: ['#5BCDF8'],
                    },
                },
                xaxis: {
                    categories: [
                        'Jan1',
                        'Jan2',
                        'Jan3',
                        'Jan4',
                        'Jan5',
                        'Jan6',
                        'Jan7',
                    ],
                    position: 'bottom',
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#5BCDF8',
                                colorTo: '#5BCDF8',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },

                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val
                        },
                    },
                },
                grid: {
                    show: true,
                    borderColor: '#E2E2E2',
                    strokeDashArray: 8,
                    position: 'back',
                    yaxis: {
                        lines: {
                            show: true,
                            borderColor: '#90A4AE',
                        },
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5,
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.5,
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
                responsive: [
                    {
                        breakpoint: 1500,
                        options: {
                            plotOptions: {
                                bar: {
                                    borderRadius: 6,
                                },
                            },
                        },
                    },
                    {
                        breakpoint: 767,
                        options: {
                            chart: {
                                height: 200,
                            },
                            plotOptions: {
                                bar: {
                                    borderRadius: 5,
                                },
                            },
                        },
                    },
                ],
            },
        }
    },
    watch: {
        chartData: {
            handler() {
                this.$refs.chart.updateOptions({
                    xaxis: {
                        categories: this.chartData.categories,
                    },
                })
                this.series = [{ data: this.chartData.series }]
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
h3 {
    font-size: rem(18px);
}
#chart {
    position: relative;
    // span {
    // 	width: 20px;
    // 	height: 20px;
    // 	background: rgba($color: #1ad598, $alpha: 0.13);
    // 	border-radius: 100%;
    // 	@include flex(center, center);
    // 	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.296' height='10.356' viewBox='0 0 10.296 10.356'%3E%3Cpath id='arrow' d='M.3,10.053a1.042,1.042,0,0,1,0-1.464L6.78,2.071H3.088A1.036,1.036,0,0,1,3.088,0H9.265a1.021,1.021,0,0,1,.454.106l.009,0,.011.005.017.009h0a1.035,1.035,0,0,1,.414.417h0l.009.018,0,.01,0,.009a1.033,1.033,0,0,1,.105.456V7.25a1.03,1.03,0,1,1-2.06,0V3.537L1.757,10.053a1.026,1.026,0,0,1-1.456,0Z' fill='%231ad598'/%3E%3C/svg%3E%0A");
    // 	background-repeat: no-repeat;
    // 	background-position: center center;
    // 	position: absolute;
    // 	top: 48%;
    // 	left: 36%;
    // 	transform: translate(-50%, -50%);
    // }
    // @media screen and (max-width: 1199px) and (min-width: 768px) {
    // 	max-width: 500px;
    // 	margin: auto;
    // }
    @media screen and (max-width: 575px) {
        padding: 0 10px 0 15px;
    }
}
h3 {
    margin-bottom: rem(15px);
}
ul {
    list-style: none;
    @include flex(center, space-around);
    flex-wrap: nowrap;
    padding-bottom: rem(15px);
    li {
        font-weight: 700;
        color: #103581;
        border-left: 2px solid #292f78;
        padding: 5px 0 5px 10px;
        &:last-child {
            border-color: #62d2ff;
        }
        p {
            font-size: rem(14px);
            font-weight: 400;
            line-height: 1;
            margin: 5px 0 0 0;
        }
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        max-width: 80%;
        margin: auto;
        li {
            &:first-child {
                margin-right: rem(25px);
            }
        }
    }
    @media screen and (max-width: 767px) {
        li {
            font-size: rem(17px);
            &:first-child {
                margin-right: rem(25px);
            }
        }
    }
    // @media screen and (max-width: 500px) {
    // 	flex-direction: column;
    // 	li {
    // 		font-size: rem(15px);
    // 		padding: 5px 0 5px 5px;
    // 		&:first-child {
    // 			margin: 0 0 rem(30px) 0;
    // 		}
    // 	}
    // }
    &.chart-arrows {
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        width: 100%;
        max-width: 100%;
        height: 30px;
        padding-bottom: 0;
        li {
            border-left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            &:first-child {
                left: -35px;
            }
            &:last-child {
                right: -27px;
            }
            a {
                background: #f7faff;
                border-radius: 7px;
                padding: 12px;
                svg {
                    width: 9px;
                }
                &:hover {
                    background: #292f7a;
                    color: #fff;
                }
            }
            @media screen and (max-width: 1600px) {
                &:first-child {
                    left: -20px;
                }
                &:last-child {
                    right: -23px;
                }
                a {
                    padding: 8px;
                }
            }
            @media screen and (max-width: 1400px) {
                &:last-child {
                    right: -20px;
                }
            }
            @media screen and (max-width: 575px) {
                &:first-child {
                    left: -30px;
                }
                &:last-child {
                    right: -3px;
                }
                a {
                    padding: 0px;
                    background: transparent;
                    border-radius: 0;
                }
            }
        }
    }
}
.flex-wrapper {
    display: flex !important;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
        align-items: center;
    }
}
[lang='ar'] {
    #chart {
        direction: ltr;
        // @media screen and (max-width: 575px) {
        //     padding: 0 15px 0 10px;
        // }
    }
    ul {
        direction: ltr;
        // li {
        //     border-right: 2px solid #292f78;
        //     border-left: 0;
        //     padding: 5px 10px 5px 0;
        // }
        // @media screen and (max-width: 1199px) and (min-width: 768px) {
        //     li {
        //         &:first-child {
        //             margin-left: rem(25px);
        //             margin-right: 0;
        //         }
        //     }
        // }
        // @media screen and (max-width: 767px) {
        //     li {
        //         &:first-child {
        //             margin-left: rem(25px);
        //             margin-right: 0;
        //         }
        //     }
        // }
        // &.chart-arrows {
        //     li {
        //         border-right: 0;
        //         &:first-child {
        //             right: -35px;
        //             left: auto;
        //         }
        //         &:last-child {
        //             left: -27px;
        //             right: auto;
        //         }
        //         @media screen and (max-width: 1600px) {
        //             &:first-child {
        //                 right: -20px;
        //             }
        //             &:last-child {
        //                 left: -23px;
        //             }
        //         }
        //         @media screen and (max-width: 1400px) {
        //             &:last-child {
        //                 left: -20px;
        //             }
        //         }
        //         @media screen and (max-width: 575px) {
        //             &:first-child {
        //                 right: -30px;
        //             }
        //             &:last-child {
        //                 left: -3px;
        //             }
        //         }
        //     }
        // }
    }
}
</style>
