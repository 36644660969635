import { VENDORS } from './actions'

import { VendorsService } from '@/common/api_services/vendors'
import TableHandler from '@/common/table-service'
import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR } from '@/store/common'

const state = {
    vendorsList: {},
    applicationsList: {},
    installmentsList: {},
    vendorProfile: {},
}

const getters = {
    vendorsList: (state) => state.vendorsList,
    applicationsList: (state) => state.applicationsList,
    installmentsList: (state) => state.installmentsList,
    vendorProfile: (state) => state.vendorProfile,
}

const mutations = {
    [VENDORS.SET_VENDOR_PROFILE]: (state, resp) => {
        state.vendorProfile = resp.data
    },
    [VENDORS.SET_VENDORS_LIST]: (state, resp) => {
        state.vendorsList = TableHandler.setVendorsTable(resp.data)
    },
    [VENDORS.SET_VISIBILITY_ROW]: (state, resp) => {
        let target = state.vendorsList.results.find((el) => el.id === resp)
        target['_showDetails'] = !target['_showDetails']
    },
    [VENDORS.SET_APPLICATIONS_LIST]: (state, resp) => {
        state.applicationsList = TableHandler.setApplicationsTable(resp.data)
    },
    [VENDORS.SET_INSTALLMENTS_LIST]: (state, resp) => {
        state.installmentsList = TableHandler.setInstallmentsTable(resp.data)
    },
    [VENDORS.SET_INSTALLMENT_VISIBILITY_ROW]: (state, resp) => {
        let target = state.installmentsList.results.find((el) => el.id === resp)
        target['_showDetails'] = !target['_showDetails']
    },
}

const actions = {
    [VENDORS.VENDORS_LIST]: ({ commit }, payload) => {
        return VendorsService.getVendorsList(payload)
            .then((resp) => {
                commit(VENDORS.SET_VENDORS_LIST, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [VENDORS.APPLICATIONS_LIST]: ({ commit }, payload) => {
        commit(FETCH_START)
        return VendorsService.getApplicationsList(payload)
            .then((resp) => {
                commit(VENDORS.SET_APPLICATIONS_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [VENDORS.INSTALLMENTS_LIST]: ({ commit }, payload) => {
        commit(FETCH_START)
        return VendorsService.getInstallmentsList(payload.id, payload.filter)
            .then((resp) => {
                commit(VENDORS.SET_INSTALLMENTS_LIST, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [VENDORS.SHOW_ROW]: ({ commit }, payload) => {
        commit(VENDORS.SET_VISIBILITY_ROW, payload)
    },
    [VENDORS.SHOW_INSTALLMENT_ROW]: ({ commit }, payload) => {
        commit(VENDORS.SET_INSTALLMENT_VISIBILITY_ROW, payload)
    },
    [VENDORS.VENDOR_PROFILE_DETAILS]: ({ commit }, payload) => {
        commit(FETCH_START)
        return VendorsService.getVendorProfileDetails(payload)
            .then((resp) => {
                commit(VENDORS.SET_VENDOR_PROFILE, resp)
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [VENDORS.UPDATE_VENDOR_PROFILE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return VendorsService.updateVendorProfile(payload.id, payload.data)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [VENDORS.DELETE_VENDOR_PROFILE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return VendorsService.deleteVendorProfile(payload.id)
            .then(() => {
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [VENDORS.APPROVE_VENDOR]: ({ commit }, payload) => {
        commit(FETCH_START)
        return VendorsService.approveVendor(payload.id, payload.changes)
            .then(() => {
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
