export const SETTINGS = {
    GET_NAV_MENU: 'getNavMenu',
    SET_NAV_MENU: 'setNavMenu',
    GET_FOOTER: 'getFooterSettings',
    SET_FOOTER: 'setFooterSettings',
    GET_SOCIAL: 'getSocialSettings',
    SET_SOCIAL: 'setSocialSettings',
    GET_COUNTRY: 'getCountry',
    SET_COUNTRY: 'setCountry',
    GET_GOVERNORATES: 'getGovernorates',
    SET_GOVERNORATES: 'setGovernorates',
    GET_COUNTRIES: 'getCountries',
    SET_COUNTRIES: 'setCountries',
    UPLOAD_FILE: 'uploadFile',
    GET_UPLOADED_FILE: 'getUploadFile',
    DELETE_FILE: 'deleteFile',
    SET_KEYWORD: 'setKeywordSearch',
    GET_KEYWORD: 'getKeywordSearch',
}
