export default [
    {
        path: '/:lang',
        meta: {
            requiresAuth: true,
            title: 'Notifications page',
        },
        redirect: { name: 'notification_index' },
        name: 'notification',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/notifications',
                meta: {
                    title: 'Notifications',
                },
                name: 'notification_index',
                component: () =>
                    import('@/modules/notifications/views/Notifications.vue'),
            },
        ],
    },
]
