import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '../common'

import { SettingService } from '@/common/api_services/settings'
import { SETTINGS } from '@/store/actions/settings'

const state = {
    navMenu: {},
    footerSettings: {},
    socialSettings: {},
    country: {},
    governoratesList: [],
    countriesList: [],
    uploadedFileInfo: {},
    keyword: '',
}

const getters = {
    navMenu: (state) => state.navMenu,
    footerSettings: (state) => state.footerSettings,
    socialSettings: (state) => state.socialSettings,
    country: (state) => state.country,
    governoratesList: (state) => state.governoratesList,
    countriesList: (state) => state.countriesList,
    uploadedFileInfo: (state) => state.uploadedFileInfo,
    keyword: (state) => state.keyword,
}

const mutations = {
    [SETTINGS.SET_NAV_MENU]: (state, resp) => {
        state.navMenu = resp.data
    },
    [SETTINGS.SET_FOOTER]: (state, resp) => {
        state.footerSettings = resp.data
    },
    [SETTINGS.SET_SOCIAL]: (state, resp) => {
        state.socialSettings = resp.data
    },
    [SETTINGS.GET_SOCIAL]: (state, resp) => {
        state.country = resp.data
    },
    [SETTINGS.SET_GOVERNORATES]: (state, resp) => {
        state.governoratesList = resp.data.results
    },
    [SETTINGS.SET_COUNTRIES]: (state, resp) => {
        state.countriesList = resp.data
    },
    [SETTINGS.GET_UPLOADED_FILE]: (state, resp) => {
        state.uploadedFileInfo = resp
    },
    [SETTINGS.GET_KEYWORD]: (state, resp) => {
        state.keyword = resp
    },
}

const actions = {
    [SETTINGS.GET_NAV_MENU]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getNavMenu(payload)
            .then((resp) => {
                commit(SETTINGS.SET_NAV_MENU, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.GET_FOOTER]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getFooterSettings(payload)
            .then((resp) => {
                commit(SETTINGS.SET_FOOTER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.GET_SOCIAL]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getSocialSettings(payload)
            .then((resp) => {
                commit(SETTINGS.SET_SOCIAL, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.GET_COUNTRY]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getCountry(payload)
            .then((resp) => {
                commit(SETTINGS.GET_SOCIAL, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.GET_GOVERNORATES]: ({ commit }, payload) => {
        SettingService.getGovernorates(payload)
            .then((resp) => {
                commit(SETTINGS.SET_GOVERNORATES, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.GET_COUNTRIES]: ({ commit }, payload) => {
        commit(FETCH_START)
        SettingService.getCountries(payload)
            .then((resp) => {
                commit(SETTINGS.SET_COUNTRIES, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.UPLOAD_FILE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return SettingService.uploadFile(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                commit(SETTINGS.GET_UPLOADED_FILE, resp.data)
                return resp.data
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.DELETE_FILE]: ({ commit }, payload) => {
        return SettingService.deleteFile(payload)
            .then((resp) => {
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [SETTINGS.SET_KEYWORD]: ({ commit }, payload) => {
        commit(SETTINGS.GET_KEYWORD, payload)
    },
}
export default {
    state,
    getters,
    mutations,
    actions,
}
