import { ApiServiceHelper } from '.'

import { SETTINGS } from '@/store/actions/settings.js'

export const GeneralHelper = {
    getGovernorates(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_GOVERNORATES, params)
    },
    getCountries(params) {
        return ApiServiceHelper.execAction(SETTINGS.GET_COUNTRIES, params)
    },
    uploadFile(params) {
        return ApiServiceHelper.execAction(SETTINGS.UPLOAD_FILE, params)
    },
    deleteFile(id) {
        return ApiServiceHelper.execAction(SETTINGS.DELETE_FILE, id)
    },
    storeGlobalKeyword(keyword) {
        return ApiServiceHelper.execAction(SETTINGS.SET_KEYWORD, keyword)
    },
}
