import { Loading } from 'notiflix'
import Vue from 'vue'
import Vuex from 'vuex'

// import { NotificationHelper } from '@/common/crud-helpers/notification'
import auth from '@/modules/auth/store'
import dashboard from '@/modules/dashboard/store'
import orders from '@/modules/orders/store'
import catalogue from '@/modules/products/store'
import transactions from '@/modules/transactions/store'
import users from '@/modules/users/store'
import vendors from '@/modules/vendors/store'
import { SOCKET_MUTATIONS, NOTIFICATION } from '@/store/actions/notification'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'
import notification from '@/store/modules/notification'
import settings from '@/store/modules/settings'

var loader_svg = require('@/assets/images/svg/logo2.svg')
Vue.use(Vuex)
Loading.init({
    customSvgUrl: loader_svg,
    svgSize: '135px',
    backgroundColor: '#ffffff',
    messageFontSize: '0px',
})
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        settings,
        notification,
        catalogue,
        users,
        orders,
        auth,
        dashboard,
        transactions,
        vendors,
    },
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
    },
    mutations: {
        [FETCH_START]: () => {
            Loading.custom(`<div class="loader">xxxx</div>`)
        },
        [FETCH_SUCCESS]: () => {
            Loading.remove()
        },
        [FETCH_ERROR]: () => {
            Loading.remove()
        },
        [SOCKET_MUTATIONS.SOCKET_ONOPEN](state) {
            state.socket.isConnected = true
        },
        [SOCKET_MUTATIONS.SOCKET_ONCLOSE](state) {
            state.socket.isConnected = false
        },
        [SOCKET_MUTATIONS.SOCKET_ONERROR]() {},
        [SOCKET_MUTATIONS.SOCKET_ONMESSAGE](state, message) {
            state.socket.message = message
            this.commit(NOTIFICATION.SET_SOCKET_NOTIFICATIONS, message)
            // NotificationHelper.getNotificationCount()
            // NotificationHelper.getNotification({
            //     limit: 10,
            //     offset: 0,
            // })
        },
        [SOCKET_MUTATIONS.SOCKET_RECONNECT]() {},
        [SOCKET_MUTATIONS.SOCKET_RECONNECT_ERROR](state) {
            state.socket.reconnectError = true
        },
    },
    actions: {
        SendMessage(context, message) {
            Vue.prototype.$socket.send(message)
        },
    },
    strict: debug,
})
