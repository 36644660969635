export default [
    {
        path: '/:lang',
        redirect: { name: 'home_index' },
        name: 'home',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/home/',
                meta: {
                    requiresGuest: true,
                    title: 'Home',
                },
                name: 'home_index',
                component: () => import('@/modules/dashboard/views/Home.vue'),
            },
        ],
    },
]
