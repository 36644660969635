export default [
    {
        path: '/:lang',
        meta: {
            name: 'approve_order_index',
        },
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/order/:uid/approve',
                name: 'approve_order',
                component: () =>
                    import('@/modules/orders/components/OrderApproved.vue'),
            },
        ],
    },
]
