<template>
    <div class="items">
        <div class="items__item type">
            <label>
                {{ $t('transactions.Report Type') }}
            </label>
            <base-select
                :options="types"
                :default-option="`${types[0]}`"
                :value="types[0]"
                @select="setTransactionType"
                className="formStyle"
            />
        </div>

        <div
            v-if="transactionType === $t('transactions.Daily')"
            class="items__item day"
        >
            <label>
                {{ $t('transactions.Day') }}
            </label>
            <b-form-datepicker
                class="datePicker"
                placeholder="dd/mm/yyyy"
                id="datepicker-dateformat2"
                :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                }"
                locale="en"
                v-model="filter.created"
            ></b-form-datepicker>
        </div>
        <div
            v-if="
                transactionType === $t('transactions.Yearly') ||
                transactionType === $t('transactions.Weekly')
            "
            class="items__item year"
        >
            <label>
                {{ $t('transactions.Year') }}
            </label>
            <date-picker
                class="year-input"
                v-model="year"
                type="year"
                @change="setYear"
                @clear="resetFilterExcept"
            ></date-picker>
        </div>
        <div
            v-if="transactionType === $t('transactions.Monthly')"
            class="items__item month"
        >
            <label>
                {{ $t('transactions.Month') }}
            </label>
            <date-picker
                class="month-input"
                v-model="month"
                type="month"
                @input="setMonth"
                @clear="resetFilterExcept"
            ></date-picker>
        </div>
        <div
            v-if="transactionType === $t('transactions.Weekly')"
            class="items__item week"
        >
            <label>
                {{ $t('transactions.Week') }}
            </label>
            <date-picker
                class="week-input"
                v-model="week"
                type="week"
                @change="setWeek"
                @clear="resetFilterExcept"
            ></date-picker>
        </div>
        <div class="items__item link">
            <button
                type="submit"
                class="btn btn-secondary"
                @click="showFilteredResults()"
            >
                {{ $t('transactions.Show') }}
            </button>
            <!--            <span v-if="!isAllFieldsSelected" class="error-msg"-->
            <!--                >Please select all fields</span-->
            <!--            >-->
        </div>
    </div>
</template>

<script>
import BaseSelect from '@/common/components/base/BaseSelect.vue'

export default {
    props: {
        info: Array,
    },
    components: {
        BaseSelect,
    },
    created() {
        this.initMonths()
    },
    data() {
        return {
            types: [
                `${this.$i18n.t('transactions.Recent Transactions')}`,
                this.$i18n.t('transactions.Daily'),
                this.$i18n.t('transactions.Weekly'),
                this.$i18n.t('transactions.Monthly'),
                this.$i18n.t('transactions.Yearly'),
            ],
            months: [],
            transactionType: null,
            created: '',
            year: 0,
            month: 0,
            week: 0,
            isAllFieldsSelected: true,
            filter: {
                limit: 13,
                offset: 0,
            },
        }
    },
    watch: {
        filter: {
            deep: true,
            handler: function (newVal) {
                if (
                    newVal.created ||
                    newVal.year ||
                    newVal.month ||
                    newVal.week
                ) {
                    this.isAllFieldsSelected = true
                }
            },
        },
        transactionType() {
            if (this.transactionType) this.isAllFieldsSelected = true
        },
    },
    methods: {
        initMonths() {
            for (let i = 1; i <= 12; i++) {
                this.months.push(i)
            }
        },
        resetFilterExcept(target) {
            let validKeys = ['limit', 'offset'].concat(target)
            if (validKeys.length) {
                Object.keys(this.filter).forEach(
                    (key) => validKeys.includes(key) || delete this.filter[key]
                )
            } else {
                Object.keys(this.filter).forEach(
                    (key) => delete this.filter[key]
                )
            }
        },
        setTransactionType(val) {
            if (val) this.transactionType = val
            else this.transactionType = null
        },
        setYear(val) {
            this.filter.year = new Date(val).getFullYear()
        },
        setMonth(val) {
            this.filter.month = this.months[new Date(val).getMonth()]
            this.filter.year = new Date(val).getFullYear()
        },
        setWeek(val) {
            let currentDate = new Date(val)
            let oneJan = new Date(currentDate.getFullYear(), 0, 1)
            let numberOfDays = Math.floor(
                (currentDate - oneJan) / (24 * 60 * 60 * 1000)
            )
            this.filter.week = Math.ceil(
                (currentDate.getDay() + 1 + numberOfDays) / 7
            )
        },
        showFilteredResults() {
            if (
                this.transactionType ===
                    this.$i18n.t('transactions.Recent Transactions') ||
                this.transactionType === null
            ) {
                this.resetFilterExcept()
            } else if (
                this.transactionType === this.$i18n.t('transactions.Daily') &&
                this.filter.created
            ) {
                this.resetFilterExcept(['created'])
            } else if (
                this.transactionType === this.$i18n.t('transactions.Weekly') &&
                this.filter.week
            ) {
                this.resetFilterExcept(['week', 'year'])
            } else if (
                this.transactionType === this.$i18n.t('transactions.Monthly') &&
                this.filter.year &&
                this.filter.month
            ) {
                this.resetFilterExcept(['year', 'month'])
            } else if (
                this.transactionType === this.$i18n.t('transactions.Yearly') &&
                this.filter.year
            ) {
                this.resetFilterExcept(['year'])
            } else this.isAllFieldsSelected = false
            this.$emit('setFilters', { ...this.filter })
        },
    },
}
</script>
<style lang="css">
.mx-datepicker.year-input,
.mx-datepicker.month-input,
.mx-datepicker.week-input {
    width: 100px;
}
.mx-input {
    font-size: 12px;
    border-radius: 16px;
    border: 2px solid #d9e1e7 !important;
}
.mx-calendar-content {
    height: auto;
}
.mx-table {
    padding: 0rem !important;
}
</style>
<style lang="scss" scoped>
.error-msg {
    color: red;
    font-size: 12px;
}
.items {
    display: flex;
    align-items: center;
    $self: &;
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
    @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #{$self}__item {
        &.type {
            width: 251px;
        }
        &.year {
            width: 120px;
        }
        &.month {
            width: 100px;
        }
        &.week {
            width: 100px;
        }
        &.link {
            width: 132px;
        }
        &:not(:last-child) {
            margin-right: rem(25px);
        }
        @media screen and (max-width: 1400px) {
            &.type {
                width: 212px;
            }
            &.year {
                width: 110px;
            }
            &.month {
                width: 90px;
            }
            &.week {
                width: 90px;
            }
            &.link {
                width: 120px;
            }
            &:not(:last-child) {
                margin-right: rem(15px);
            }
        }
        @media screen and (max-width: 1024px) {
            &.type {
                width: 29%;
            }
            &.year {
                width: 20%;
            }
            &.month {
                width: 20%;
            }
            &.week {
                width: 20%;
            }
            &.link {
                width: 20%;
            }
            &:not(:last-child) {
                margin-right: rem(15px);
            }
        }
        @media screen and (max-width: 991px) {
            width: 48% !important;
            margin-bottom: rem(25px);
            &.link {
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:not(:last-child) {
                margin-right: 0;
            }
        }
        @media screen and (max-width: 414px) {
            width: 100% !important;
        }
    }
}

[lang='ar'] {
    .items__item {
        &:not(:last-child) {
            margin-left: rem(25px);
            margin-right: 0;
        }
        @media screen and (max-width: 1400px) {
            &:not(:last-child) {
                margin-left: rem(15px);
            }
        }
        @media screen and (max-width: 1025px) {
            &:not(:last-child) {
                margin-left: rem(15px);
            }
        }
        @media screen and (max-width: 991px) {
            &:not(:last-child) {
                margin-left: 0;
            }
        }
    }
}
</style>
