import { TRANSACTIONS } from './actions'

import { TransactionsService } from '@/common/api_services/transactions'
import TableHandler from '@/common/table-service'
import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR } from '@/store/common'

const state = {
    transactionsList: {},
    transactionsReport: '',
}

const getters = {
    transactionsList: (state) => state.transactionsList,
    transactionsReport: (state) => state.transactionsReport,
}

const mutations = {
    [TRANSACTIONS.SET_TRANSACTIONS_LIST]: (state, resp) => {
        state.transactionsList = TableHandler.setTransactionsTable(resp.data)
    },
    [TRANSACTIONS.SET_VISIBILITY_ROW]: (state, resp) => {
        let target = state.transactionsList.results.find((el) => el.id === resp)
        target['_showDetails'] = !target['_showDetails']
    },
    [TRANSACTIONS.SET_TRANSACTIONS_REPORT]: (state, resp) => {
        state.transactionsReport = resp.data
    },
}

const actions = {
    [TRANSACTIONS.VENDOR_TRANSACTIONS_LIST]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return TransactionsService.getVendorTransactionsList(
            payload.id,
            payload.filter
        )
            .then((resp) => {
                commit(TRANSACTIONS.SET_TRANSACTIONS_LIST, resp)
                return resp
                // commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                // commit(FETCH_ERROR)
                throw err
            })
    },
    [TRANSACTIONS.TRANSACTIONS_LIST]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return TransactionsService.getTransactionsList(payload)
            .then((resp) => {
                commit(TRANSACTIONS.SET_TRANSACTIONS_LIST, resp)
                return resp
                // commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                // commit(FETCH_ERROR)
                throw err
            })
    },
    [TRANSACTIONS.SHOW_ROW]: ({ commit }, payload) => {
        commit(TRANSACTIONS.SET_VISIBILITY_ROW, payload)
    },
    [TRANSACTIONS.TRANSACTIONS_REPORT]: ({ commit }, payload) => {
        commit(FETCH_START)
        return TransactionsService.getTransactionsReport(payload)
            .then((resp) => {
                commit(TRANSACTIONS.SET_TRANSACTIONS_REPORT, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
