import { CATALOGUE } from './actions'

import { CatalogueService } from '@/common/api_services/products'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'

const state = {
    productList: [],
    productDetail: {},
}

const getters = {
    productList: (state) => state.productList,
    productDetail: (state) => state.productDetail,
}

const mutations = {
    [CATALOGUE.PRODUCT_LIST]: (state, resp) => {
        state.productList = resp.data.results
    },
    [CATALOGUE.GET_PRODUCT_DETAILS]: (state, resp) => {
        state.productDetail = resp.data
    },
}

const actions = {
    // Catalogue actions
    [CATALOGUE.PRODUCT_LIST]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return CatalogueService.getProduct(payload)
            .then((resp) => {
                commit(CATALOGUE.PRODUCT_LIST, resp)
                // commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                // commit(FETCH_ERROR)
                throw err
            })
    },
    [CATALOGUE.GET_PRODUCT_DETAILS]: ({ commit }, payload) => {
        return CatalogueService.get(payload)
            .then((resp) => {
                commit(CATALOGUE.GET_PRODUCT_DETAILS, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [CATALOGUE.CREATE]: ({ commit, dispatch }, payload) => {
        commit(FETCH_START)
        return CatalogueService.create(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                dispatch(CATALOGUE.PRODUCT_LIST)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
