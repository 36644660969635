<template>
    <div class="search">
        <validation-provider
            :name="name"
            v-slot="{ errors, passed }"
            :rules="`${[required ? 'required' : '']}|${[
                type === 'text' ? '' : type,
            ]}`"
        >
            <input
                :placeholder="placeholder"
                :type="type"
                class="form-control search-bar"
                :class="[
                    errors.length ? 'error-border' : '',
                    passed && !readonly ? 'success-border' : '',
                    readonly ? 'readonly' : '',
                ]"
                v-model="search"
                @keyup="lunchSearch"
            />
            <button :disabled="!search" @click="lunchSearch">
                <svg-icon
                    fill="currentColor"
                    icon-id="search"
                    icon-viewbox="0 0 20.575 21.033"
                    width="20.575"
                    height="21.033"
                ></svg-icon>
            </button>
            <button v-if="search !== ''" class="close" @click="resetSearch">
                x
            </button>
        </validation-provider>
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        placeholder: String,
        required: Boolean,
        readonly: Boolean,
        name: String,
    },
    data() {
        return {
            search: '',
        }
    },
    methods: {
        lunchSearch() {
            this.$emit('search', this.search)
        },
        resetSearch() {
            this.search = ''
            this.lunchSearch()
        },
    },
}
</script>
<style scoped>
/* Form Validation Classes */
.error {
    color: red !important;
}
.error-border,
.error-border:hover,
.error-border:focus {
    border: 1px solid red !important;
}
.success-border,
.success-border:hover,
.success-border:focus {
    border: 1px solid green !important;
}
.success {
    color: green !important;
}
</style>

<style lang="scss">
.search {
    position: relative;
    .form-control {
        border: 1px solid #d9e1e7;
        padding-right: 55px;
        border-radius: 14px;
    }
    button {
        position: absolute;
        right: 18px;
        top: 46%;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        width: 20px;
        height: 20px;
        &.close {
            color: #9f9fa8;
            right: 38px;
            // top: 45%;
            font-size: 20px;
        }
        &:hover {
            opacity: 0.5;
        }
    }
}

[lang='ar'] {
    .search {
        .form-control {
            padding-left: 55px;
            padding-right: rem(21px);
            @media screen and (max-width: 991px) {
                padding-right: rem(16px);
            }
        }
        button {
            left: 18px;
            right: auto;
            svg {
                transform: scaleX(-1);
            }
            &.close {
                left: 38px;
                right: auto;
            }
        }
    }
}
</style>
