export const DASHBOARD = {
    ACTIVITY: 'getActivity',
    SET_ACTIVITY: 'setActivity',
    OVERVIEW: 'getOverview',
    CHARTS: 'getCharts',
    SET_CHARTS: 'setCharts',
    PERCENTAGE: 'getPercentage',
    SET_PERCENTAGE: 'setPercentage',
    GET_PIE_CHART_DATA: 'getPieChartData',
    SET_PIE_CHART_DATA: 'setPieChartData',
}
export const HOME = {
    GET_FOOTER: 'getFooter',
    SET_FOOTER: 'setFooter',
    GET_HOME_INFO: 'getHomeInfo',
    SET_HOME_INFO: 'setHomeInfo',
    GET_HOME_INFO_ADMIN: 'getHomeInfoAdmin',
    SET_HOME_INFO_ADMIN: 'setHomeInfoAdmin',
    EDIT_DETAILS: 'editHomeDetails',
    EDIT_FOOTER: 'editFooterDetails',
    STORE_NEW_DETAILS: 'storeHomeDetails',
    SET_STORE_NEW_DETAILS: 'setStoreHomeDetails',
    EDIT_SERVICE: 'editHomeServices',
    DELETE_SERVICE: 'deleteService',
    DELETE_SOCAIL_LINK: 'deleteSocialLink',
    CREATE_SOCIAL: 'createSocialMedia',
}
