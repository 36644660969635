var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, passed }){return [_c('v-select',{class:[
                        errors.length && !_vm.bordered
                            ? ``
                            : (errors.length && _vm.bordered) || _vm.customErrors
                            ? `error-border`
                            : _vm.className,
                        passed && !_vm.readonly && !_vm.bordered
                            ? ``
                            : passed && _vm.bordered && !_vm.customErrors
                            ? `success-border`
                            : _vm.className,
                        _vm.readonly ? `readonly` : _vm.className,
                    ],attrs:{"options":_vm.options_arr,"searchable":_vm.searchable,"filterable":_vm.filterable,"readonly":_vm.readonly,"disabled":_vm.readonly,"placeholder":_vm.placeholder},on:{"input":_vm.setSelected,"search":_vm.fetchOptions},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._t("option",null,{"option":option})]}},{key:"selected-option",fn:function(option){return [_vm._t("selected-option",null,{"selectedOption":option})]}}],null,true),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},[(_vm.noOptionsText.length)?_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.noOptionsText)+" ")]):_vm._e()]),(errors.length)?_c('span',{staticClass:"invalid-feedback",class:_vm.absolute ? 'position-absolute' : ''},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }