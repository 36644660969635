var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploaded && !_vm.readonlyInput),expression:"!uploaded && !readonlyInput"}],staticClass:"form-group"},[(_vm.label)?_c('label',{staticClass:"my-2"},[_c('span',{staticClass:"text-dark custom-name-not-uploaded",style:([
                        _vm.setWidthName
                            ? { width: '670px!important' }
                            : { width: '500px!important' },
                    ])},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger my-2"},[_vm._v("*")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"upload-file"},[_c('input',{ref:"refFile",attrs:{"type":"file","name":"file","id":"file-input","accept":_vm.accept,"multiple":_vm.multiple},on:{"change":_vm.uploadFile}}),_vm._v(" "+_vm._s(_vm.button_text || _vm.$t('general.Upload'))+" ")]),_c('br'),(_vm.required)?_c('small',{staticClass:"text-danger mx-2"},[_vm._v("( "+_vm._s(_vm.getFileAccept(_vm.accept))+" File )")]):_vm._e(),(!_vm.end)?_c('span',{staticClass:"vertical-line"}):_vm._e(),_c('validation-provider',{ref:"provider",attrs:{"name":_vm.name,"rules":_vm.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.url}}),(errors.length)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t( `general.${ errors[0][errors[0].length - 1] === '.' ? errors[0].slice(0, -1) : errors[0] }` )))]):_vm._e()]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploaded),expression:"uploaded"}],staticClass:"form-group success"},[_c('label',{staticClass:"my-2"},[(_vm.label)?_c('span',{staticClass:"text-dark custom-name-uploaded",style:([
                        _vm.setWidthName
                            ? { width: '570px!important' }
                            : { width: '400px!important' },
                    ])},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger my-2"},[_vm._v(" *")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"inline-buttons"},[_c('a',{staticClass:"btn btn-primary view",on:{"click":_vm.view}},[_vm._v(" "+_vm._s(_vm.$t('general.View'))+" ")]),(!_vm.readonlyInput && !_vm.modify)?_c('a',{staticClass:"btn btn-danger delete",on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t('general.Delete'))+" ")]):(!_vm.readonlyInput && _vm.modify)?_c('a',{staticClass:"btn btn-dark view",on:{"click":_vm.replace}},[_vm._v(" "+_vm._s(_vm.$t('general.Replace'))+" ")]):_vm._e()]),_c('br'),(_vm.required)?_c('small',{staticClass:"text-danger my-2"},[_vm._v("( "),_c('strong',[_vm._v("Only "+_vm._s(_vm.getFileAccept(_vm.accept))+" Files")]),_vm._v(" )")]):_vm._e(),(!_vm.end)?_c('span',{staticClass:"vertical-line"}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploaded && _vm.readonlyInput),expression:"!uploaded && readonlyInput"}],staticClass:"form-group"},[_c('label',{staticClass:"my-2"},[(_vm.label)?_c('span',{staticClass:"text-dark custom-name-uploaded",style:([
                        _vm.setWidthName
                            ? { width: '570px!important' }
                            : { width: '400px!important' },
                    ])},[_vm._v(_vm._s(_vm.label)+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]):_vm._e(),_c('br'),(_vm.required)?_c('small',[_vm._v("( "),_c('strong',[_vm._v("Only "+_vm._s(_vm.getFileAccept(_vm.accept))+" Files")]),_vm._v(" )")]):_vm._e()]),(!_vm.end)?_c('span',{staticClass:"vertical-line"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }