const CookieHandler = {
    setCookie(name, value) {
        document.cookie = name + '=' + value + '; Path=/;'
    },
    deleteCookie(name) {
        document.cookie =
            name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    },
    getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    },
}

export default CookieHandler
