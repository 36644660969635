import { cloneDeep } from 'lodash'

import { ORDERS } from './actions'

import { OrderService } from '@/common/api_services/orders'
import { i18n } from '@/common/i18n'
import TableHandler from '@/common/table-service'
import formatter from '@/plugins/usd-format'
import { FETCH_SUCCESS, FETCH_ERROR, FETCH_START } from '@/store/common'

const state = {
    ordersList: [],
    order: {},
    newOrder: {
        items: [],
        attachments_to_preview: [],
        attachments: [],
        customer_name: '',
        total_cost: '',
        customer_id: '',
        dayOfInstallment: '',
        numberOfInstallment: '',
        notes: '',
    },
    numberOfInstallments: [],
}

const getters = {
    ordersList: (state) => state.ordersList,
    order: (state) => state.order,
    newOrderInfo: (state) => state.newOrder,
    numberOfInstallments: (state) => state.numberOfInstallments,
}

const mutations = {
    [ORDERS.SET_LIST]: (state, resp) => {
        state.ordersList = TableHandler.setOrdersTable(resp.data)
    },
    [ORDERS.SET_ORDER]: (state, resp) => {
        resp.data.items.forEach((item) => {
            item.total = formatter.makeItReadable(item.total, 'iqd')
            item.total = item.total.slice(0, item.total.indexOf('.'))
            item.price = formatter.makeItReadable(
                item.unit_price.slice(0, item.unit_price.indexOf('.')),
                'iqd'
            )
        })
        resp.data.created = new Date(resp.data.created).toLocaleDateString()
        if (resp.data.order_status === 'New')
            resp.data.order_status = i18n.t('orders.Pending')
        resp.data.total = formatter.makeItReadable(resp.data.total, 'iqd')
        resp.data.total = resp.data.total.slice(0, resp.data.total.indexOf('.'))
        state.order = resp.data
    },
    [ORDERS.SET_VISIBILITY_ROW]: (state, resp) => {
        let target = state.ordersList.results.find((el) => el.id === resp)
        target['_showDetails'] = !target['_showDetails']
    },
    [ORDERS.SET_STORE_NEW_ORDER]: (state, resp) => {
        state.newOrder = cloneDeep(resp)
    },
    [ORDERS.SET_INSTALLMENTS]: (state, resp) => {
        state.numberOfInstallments = resp.data
    },
}

const actions = {
    [ORDERS.LIST]: ({ commit }, payload) => {
        return OrderService.query(payload)
            .then((resp) => {
                commit(ORDERS.SET_LIST, resp)
                return resp
            })
            .catch((err) => {
                throw err
            })
    },
    [ORDERS.VENDOR_ORDERS_LIST]: ({ commit }, payload) => {
        // commit(FETCH_START)
        return OrderService.vendorOrdersList(payload.id, payload.filter)
            .then((resp) => {
                commit(ORDERS.SET_LIST, resp)
                // commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                // commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.GET_ORDER]: ({ commit }, pk) => {
        commit(FETCH_START)
        return OrderService.get(pk)
            .then((resp) => {
                commit(ORDERS.SET_ORDER, resp)
                commit(FETCH_SUCCESS)
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.SHOW_ROW]: ({ commit }, payload) => {
        commit(ORDERS.SET_VISIBILITY_ROW, payload)
    },
    [ORDERS.STORE_NEW_ORDER]: ({ commit }, payload) => {
        commit(ORDERS.SET_STORE_NEW_ORDER, payload)
    },
    [ORDERS.NEW_ORDER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return OrderService.create(payload)
            .then((resp) => {
                commit(ORDERS.SET_STORE_NEW_ORDER, {})
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.PRINT]: ({ commit }, payload) => {
        commit(FETCH_START)
        return OrderService.print(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.GET_INSTALLMENTS]: ({ commit }, payload) => {
        commit(FETCH_START)
        return OrderService.getInstallments(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                commit(ORDERS.SET_INSTALLMENTS, resp)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.SEND_EMAIL]: ({ commit }, payload) => {
        commit(FETCH_START)
        return OrderService.sendEmail(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.RESCHEDULE]: ({ commit }, payload) => {
        commit(FETCH_START)
        return OrderService.rescheduleInstallment(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
    [ORDERS.DELETE_ORDER]: ({ commit }, payload) => {
        commit(FETCH_START)
        return OrderService.deleteOrder(payload)
            .then((resp) => {
                commit(FETCH_SUCCESS)
                return resp
            })
            .catch((err) => {
                commit(FETCH_ERROR)
                throw err
            })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
