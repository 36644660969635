import { register } from 'register-service-worker'

import { apiBase } from '@/conf'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('App is being served from cache by a service worker')
        },
        registered(registration) {
            console.log('Registration: ', registration)
            initialiseState(registration)
        },
        cached() {
            console.log('Cached:')
        },
        updatefound(registration) {
            console.log('UpdateFound: ', registration)
        },
        updated(registration) {
            console.log('Updated: ', registration)
            // notifyUserAboutUpdate(registration.waiting)
        },
        offline() {
            console.log('Offline')
        },
        error(error) {
            console.log('error', error)
        },
    })
    const initialiseState = (reg) => {
        if (!reg.showNotification) {
            showNotAllowed("Showing notifications isn't supported ☹️😢")
            return
        }
        if (Notification.permission === 'denied') {
            showNotAllowed('You prevented us from showing notifications ☹️🤔')
            return
        }
        if (!'PushManager' in window) {
            showNotAllowed("Push isn't allowed in your browser 🤔")
            return
        }
        subscribe(reg)
    }
    const showNotAllowed = (message) => {
        const button = document.querySelector('form>button')
        button.innerHTML = `${message}`
        button.setAttribute('disabled', 'true')
    }

    const subscribe = async (reg) => {
        const subscription = await reg.pushManager.getSubscription()
        if (subscription) {
            sendSubData(subscription)
            return
        }

        // const vapidMeta = document.querySelector('meta[name="vapid-key"]')
        // const key = vapidMeta.content
        const key =
            'BANhXZ_SWCe-NAMRu7rS6zkypEj2in3bSgbD2WZjY5rGpEToqMnpy0x-aK9BWO4SgxJIeNrRc6HgeTbUxK9ElTY'
        const options = {
            userVisibleOnly: true,
            // if key exists, create applicationServerKey property
            ...(key && {
                applicationServerKey: urlB64ToUint8Array(key),
            }),
        }

        const sub = await reg.pushManager.subscribe(options)
        sendSubData(sub)
    }
    const urlB64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/')

        const rawData = window.atob(base64)
        const outputArray = new Uint8Array(rawData.length)
        return outputArray.map((output, index) => rawData.charCodeAt(index))
    }
    const sendSubData = async (subscription) => {
        const browser = navigator.userAgent
            .match(/(firefox|msie|chrome|safari|trident)/gi)[0]
            .toLowerCase()
        const data = {
            status_type: 'subscribe',
            subscription: subscription.toJSON(),
            browser: browser,
        }
        // ${apiBase()}notifications/
        const res = await fetch(`${apiBase()}webpush/save_information/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            credentials: 'include',
        })

        handleResponse(res)
    }
    const handleResponse = (res) => {
        console.log(res.status)
    }
}
