<template>
    <validation-provider
        :name="name"
        v-slot="{ errors, passed }"
        :rules="rules"
    >
        <input
            :style="textRight ? 'text-align: right' : ''"
            :placeholder="placeholder"
            v-model="inputValue"
            class="form-control"
            :type="type"
            :readonly="readonly"
            :class="[
                errors.length && !bordered
                    ? `error-border-bottom`
                    : (errors.length && bordered) || customErrors
                    ? `error-border`
                    : className,
                passed && !readonly && !bordered
                    ? `success-border-bottom`
                    : passed && bordered && !customErrors
                    ? `success-border`
                    : className,
                readonly ? `readonly` : className,
            ]"
            @input="$emit('input', $event.target.value)"
        />
        <span
            class="invalid-feedback"
            :class="absolute ? 'position-absolute' : ''"
            v-if="errors.length"
            >{{ errors[0] }}</span
        >
    </validation-provider>
</template>

<script>
export default {
    props: {
        name: String,
        placeholder: String,
        value: {
            default: '',
            type: [String, Number],
        },
        required: Boolean,
        readonly: Boolean,
        type: String,
        className: String,
        loginInterface: Boolean,
        bordered: Boolean,
        absolute: {
            default: false,
            type: Boolean,
        },
        confirmPassword: {
            default: false,
            type: Boolean,
        },
        rules: String,
        customErrors: Boolean,
        textRight: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            inputValue: '',
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function (newVal, oldVal) {
                this.inputValue = newVal
            },
        },
    },
}
</script>
<style lang="scss" scoped>
/* Form Validation Classes */
.error {
    color: red !important;
}
.error-border-bottom,
.error-border-bottom:hover,
.error-border-bottom:focus {
    border-bottom: 1px solid red !important;
}
.error-border,
.error-border:hover,
.error-border:focus {
    border: 1px solid red !important;
}
.success-border-bottom,
.success-border-bottom:hover,
.success-border-bottom:focus {
    border-bottom: 1px solid green !important;
}
.success-border,
.success-border:hover,
.success-border:focus {
    border: 1px solid green !important;
}
.success {
    color: green !important;
}
.invalid-feedback {
    display: block;
}
</style>
