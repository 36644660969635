export const ORDERS = {
    LIST: 'getOrdersList',
    VENDOR_ORDERS_LIST: 'getVendorOrdersList',
    GET_ORDER: 'getOrder',
    UPDATE_ORDER: 'updateOrder',
    SET_LIST: 'setOrdersList',
    SET_ORDER: 'setOrder',
    SHOW_ROW: 'showOrder',
    SET_VISIBILITY_ROW: 'setVisibilityOrder',
    NEW_ORDER: 'createNewOrder',
    SET_NEW_ORDER: 'setNewOrder',
    STORE_NEW_ORDER: 'storeNewOrder',
    SET_STORE_NEW_ORDER: 'setStoreNewOrder',
    PRINT: 'printOrder',
    GET_INSTALLMENTS: 'getInstallments',
    SET_INSTALLMENTS: 'setInstallments',
    SEND_EMAIL: 'sendPaymentsEmail',
    RESCHEDULE: 'rescheduleInstallment',
    DELETE_ORDER: 'deleteOrder',
}
