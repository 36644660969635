export default [
    {
        path: '/:lang',
        meta: {
            requiresAuth: true,
            title: 'Customers page',
        },
        redirect: { name: 'customers_index' },
        name: 'users',
        component: () => import('@/common/views/RouterBase'),
        children: [
            {
                path: '/:lang/customers',
                meta: {
                    title: 'Customers',
                },
                props: true,
                name: 'customers_index',
                component: () => import('@/modules/users/views/Customers.vue'),
            },
            {
                path: '/:lang/profile',
                meta: {
                    title: 'Account Settings',
                },
                name: 'profile',
                component: () => import('@/modules/users/views/Profile.vue'),
            },
        ],
    },
]
