export const modifyInputs = {
    props: {
        disabledAll: {
            type: Boolean,
            default: false,
        },
        modify: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            modifyToggle: false,
            readonlyInput: false,
        }
    },
    created() {
        this.setReadonly()
    },
    methods: {
        setReadonly() {
            if (this.readonly) {
                this.readonlyInput = true
            } else if (this.disabledAll) {
                this.readonlyInput = !this.modifyToggle
            } else {
                this.readonlyInput = this.readonly
            }
        },
        modifyInput() {
            this.modifyToggle = !this.modifyToggle
            this.setReadonly()
        },
    },
    watch: {
        readonly() {
            this.setReadonly()
        },
    },
}
