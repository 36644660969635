<template>
    <div>
        <BaseIcons />

        <!-- <FixedHeader :threshold="150">   -->
        <header class="header">
            <div class="container-fluid">
                <div class="flex-wrapper">
                    <a class="header__menu-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                    <div class="header__logo">
                        <router-link to="/">
                            <img
                                v-if="userDetails && userDetails.profile_pic"
                                :src="userDetails.profile_pic"
                                alt=""
                                class="img-fluid"
                            />
                            <img
                                v-else
                                src="@/assets/images/svg/logo.svg"
                                alt=""
                                class="img-fluid"
                            />
                        </router-link>
                        <div class="header__logo--text">
                            <p>
                                {{
                                    userDetails && userDetails.full_name
                                        ? userDetails.full_name
                                        : ''
                                }}
                            </p>
                            <p>
                                {{
                                    userDetails && userDetails.type
                                        ? userDetails.type
                                        : ''
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="header__info">
                        <div class="header__info--serach hide-on-lg">
                            <form>
                                <div class="form-group">
                                    <baseSelect
                                        searchable
                                        :filterable="false"
                                        v-model="search.label"
                                        :fetch-options="fetchSearchList"
                                        :options="searchResults"
                                        @select="selectSearch"
                                        :placeholder="$t('general.Search')"
                                        :no-options-text="
                                            $t(
                                                'general.sorry no matching options'
                                            )
                                        "
                                        name="search"
                                        className="formStyle"
                                    />
                                </div>
                            </form>
                        </div>
                        <ul>
                            <!--                            <li>-->
                            <!--                                <a-->
                            <!--                                    v-if="$route.name === 'notification_index'"-->
                            <!--                                    :href="-->
                            <!--                                        $router.resolve({-->
                            <!--                                            name: 'notification_index',-->
                            <!--                                        }).href-->
                            <!--                                    "-->
                            <!--                                >-->
                            <!--                                    <svg-icon-->
                            <!--                                        :class="{-->
                            <!--                                            'notification-icon':-->
                            <!--                                                socketNotifications.length,-->
                            <!--                                        }"-->
                            <!--                                        icon-id="notifications"-->
                            <!--                                        icon-viewbox="0 0 17.189 19.817"-->
                            <!--                                    ></svg-icon>-->
                            <!--                                    <span></span>-->
                            <!--                                </a>-->
                            <!--                                <router-link-->
                            <!--                                    v-else-->
                            <!--                                    :to="`/${$i18n.locale}/notifications`"-->
                            <!--                                >-->
                            <!--                                    <svg-icon-->
                            <!--                                        :class="{-->
                            <!--                                            'notification-icon':-->
                            <!--                                                socketNotifications.length,-->
                            <!--                                        }"-->
                            <!--                                        icon-id="notifications"-->
                            <!--                                        icon-viewbox="0 0 17.189 19.817"-->
                            <!--                                    ></svg-icon>-->
                            <!--                                    <span></span>-->
                            <!--                                </router-link>-->
                            <!--                            </li>-->
                            <!-- <li>
                                <a href="javascript:void(0)">
                                    <svg-icon
                                        icon-id="call-center"
                                        icon-viewbox="0 0 21.354 22.371"
                                    ></svg-icon>
                                </a>
                            </li> -->
                            <li>
                                <a
                                    class="px-5"
                                    href="javascript:void(0)"
                                    @click="changeLocale(otherLocale)"
                                >
                                    <small>
                                        {{ otherLangText }}
                                    </small>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <!-- </FixedHeader> -->
    </div>
</template>
<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

import BaseSelect from '@/common/components/base/BaseSelect.vue'
import BaseIcons from '@/common/components/base/BaseSvgIcons.vue'
import cookieHandler from '@/common/cookies-service'
import { GeneralHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'

export default {
    props: {
        userDetails: Object,
    },
    components: {
        BaseIcons,
        BaseSelect,
    },
    data() {
        return {
            search: {
                label: '',
                search_id: '',
                type: '',
                url: '',
                id: '',
            },
            searchResults: [],
        }
    },
    mounted() {
        let button = document.querySelector('.header__menu-icon')
        let body = document.querySelector('body')
        let html = document.querySelector('html')

        // Add class to the element
        button.addEventListener('click', function () {
            body.classList.add('menu-active')
            html.classList.add('no-scroll')
        })
    },
    methods: {
        selectSearch(val) {
            if (val) {
                GeneralHelper.storeGlobalKeyword(val.name)
                val.type === 'dashboardorder' &&
                this.$route.name !== 'orders_index'
                    ? this.$router.push({
                          name: 'orders_index',
                      })
                    : val.type === 'user' &&
                      this.$route.name !== 'customers_index' &&
                      !val.is_vendor
                    ? this.$router.push({
                          name: 'customers_index',
                      })
                    : val.is_vendor
                    ? this.$router.push({
                          name: 'vendor_index',
                      })
                    : ''
            } else {
                GeneralHelper.storeGlobalKeyword('')
            }
        },
        fetchSearchList: debounce(function (search, loading) {
            if (search.length > 1) {
                loading(true)
                this.search.label = search
                UsersHelper.search({ q: search }).then((resp) => {
                    if (resp.data) {
                        if (resp.data.results.length > 0) {
                            this.searchResults = []
                            resp.data.results.map((search, i) => {
                                this.searchResults.push({
                                    label:
                                        search.type === 'dashboardorder'
                                            ? this.$i18n.t('orders.Order No') +
                                              ': ' +
                                              search.name
                                            : search.is_vendor
                                            ? this.$i18n.t('general.vendor') +
                                              ': ' +
                                              search.name
                                            : this.$i18n.t(
                                                  'customers.Customer'
                                              ) +
                                              ': ' +
                                              search.name,
                                    search_id: search.id,
                                    name: search.name,
                                    type: search.type,
                                    url: search.url,
                                    id: `${i}`,
                                    is_vendor: search.is_vendor
                                        ? search.is_vendor
                                        : false,
                                })
                            })
                        } else {
                            this.searchResults = []
                            this.$base_notify.failure(
                                `${this.$i18n.t(
                                    'general.No results found with provided keywords'
                                )}`
                            )
                        }
                    }
                    loading(false)
                })
            }
        }, 350),
        changeLocale(locale) {
            this.$i18n.locale = locale
            cookieHandler.setCookie('locale', this.$i18n.locale)
            this.$router.push({
                params: { lang: locale },
                name: this.$route.name,
            })
            window.location.reload()
        },
    },
    computed: {
        ...mapGetters(['socketNotifications']),
        otherLangText() {
            return this.$i18n.locale === 'ar' ? 'English' : 'العربية'
        },
        otherLocale() {
            return this.$i18n.locale === 'en' ? 'ar' : 'en'
        },
    },
    watch: {},
}
</script>
<style lang="scss" scoped>
.notification-icon {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.header {
    padding: rem(30px) 0 rem(40px);
    /deep/ {
        .dropdown {
            .dropdown-toggle {
                border: 0;
                background: transparent;
                outline: none !important;
                padding: 0;
                height: auto;
                width: auto;
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg id='svgexport-6_90_' data-name='svgexport-6 (90)' xmlns='http://www.w3.org/2000/svg' width='11.555' height='6.587' viewBox='0 0 11.555 6.587'%3E%3Cg id='Group_32811' data-name='Group 32811' transform='translate(0)'%3E%3Cpath id='Path_58677' data-name='Path 58677' d='M5.778,103.727a.807.807,0,0,1-.572-.237L.238,98.522a.809.809,0,0,1,1.144-1.144l4.4,4.4,4.4-4.4a.809.809,0,1,1,1.144,1.144L6.35,103.491A.807.807,0,0,1,5.778,103.727Z' transform='translate(-0.001 -97.141)' fill='%23292f78'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    border: 0;
                    width: 11px;
                    height: 7px;
                }
                img {
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    overflow: hidden;
                }
            }
            .dropdown-menu {
                left: auto !important;
                right: 0 !important;
                transform: none !important;
                top: 38px !important;
                border: 2px solid var(--primary);
                background: var(--primary);
                border-radius: 8px;
                padding: 0;
                li {
                    padding: rem(10px);
                    margin: 0;
                    .dropdown-item {
                        color: #fff;
                    }
                    &.language {
                        a {
                            font-family: $arabic-font;
                        }
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid #828791;
                    }
                }
            }
        }
    }
    &__menu-icon {
        display: none;
        z-index: 99;
        cursor: pointer;
        span {
            width: 25px;
            height: 3px;
            border-radius: 10px;
            background: var(--primary);
            display: block;
            position: relative;
            z-index: 9;
            &:not(:last-child) {
                margin-bottom: rem(8px);
            }
        }
    }
    &__logo {
        width: 228px;
        flex: 0 0 228px;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 5px;
        img {
            height: 60px;
            @media screen and (max-width: 991px) {
                height: 50px;
            }
        }
        &--text {
            padding-left: 13px;
            p {
                color: #0e1446;
                line-height: 1;
                margin: 0;
                font-size: rem(20px);
                font-weight: 600;
                font-family: $primary-font;
                &:last-child {
                    font-family: $reboto-font;
                    font-size: rem(14px);
                    font-weight: 500;
                    margin-top: 5px;
                }
            }
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
    }
    &__info {
        flex: 1;
        background: #fff;
        border-radius: 20px;
        @include flex(center, space-between);
        padding: 10px rem(22px);
        &--serach {
            .form-group {
                margin: 0;
                /deep/ {
                    .form-group {
                        margin: auto;
                    }
                    .formStyle {
                        width: 320px;
                        @media screen and (min-width: 1400px) {
                            width: 380px;
                        }
                    }
                }
            }
            /deep/ {
                .form-control {
                    // background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.575' height='21.033' viewBox='0 0 20.575 21.033'%3E%3Cg id='icon' transform='translate(0.75 0.75)'%3E%3Cg id='Iconly_Light_Search' data-name='Iconly/Light/Search'%3E%3Cg id='Search'%3E%3Ccircle id='Ellipse_739' cx='8.989' cy='8.989' r='8.989' fill='none' stroke='%2392929d' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='Line_181' d='M0,0,3.524,3.515' transform='translate(15.24 15.707)' fill='none' stroke='%2392929d' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    // background-position: 19px center;
                    // background-repeat: no-repeat;
                    background-color: #f7faff;
                    border: 0;
                    width: 313px;
                    color: #92929d;
                    font-weight: 500;
                    padding-left: 55px;
                    padding-right: 30px;
                    font-size: rem(18px);
                    &::placeholder {
                        color: #92929d;
                        font-weight: 500;
                    }
                    border: 1px transparent !important;
                    &:hover,
                    &:focus {
                        border: transparent !important;
                    }
                }
                button {
                    left: 15px;
                    &.close {
                        right: 10px;
                        left: auto;
                    }
                }
            }
        }
        ul {
            list-style: none;
            @include flex(center, flex-end);
            li {
                &:not(:last-child) {
                    margin-right: rem(18px);
                }
                a {
                    color: #809fb8;
                    border: 2px solid #d9e1e7;
                    border-radius: 15px;
                    width: rem(50px);
                    height: rem(50px);
                    @include flex(center, center);
                    position: relative;
                    span {
                        display: inline-block;
                        position: absolute;
                        width: 9px;
                        height: 9px;
                        border-radius: 100%;
                        background-color: #fb4444;
                        right: 12px;
                        top: 10px;
                        border: 2px solid #fff;
                        @media screen and (max-width: 1500px) {
                            right: 6px;
                            top: 6px;
                        }
                        @media screen and (max-width: 991px) {
                            right: 0;
                            top: 0;
                        }
                    }
                    svg {
                        width: 19px;
                    }
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            background-color: #f7faff;
                            border-color: #f7faff;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1500px) {
        &__logo {
            max-width: 198px;
            flex: 0 0 198px;
        }
        &__info {
            ul {
                li {
                    a {
                        border-radius: 12px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        padding: rem(20px) 14px;
        position: fixed;
        top: 0;
        width: 100%;
        background: #f7faff;
        z-index: 1;
        .flex-wrapper {
            align-items: center;
            justify-content: space-between;
        }
        &__info {
            background: transparent;
            border-radius: 0;
            flex: none;
            padding: 0;
            ul {
                li {
                    a {
                        border: 0;
                        padding: 0;
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
        &__logo {
            flex: none;
            max-width: none;
            width: auto;
            margin: auto;
            text-align: center;
            display: none;
        }
        &__menu-icon {
            display: block;
        }
    }
}

[lang='ar'] {
    .header {
        /deep/ {
            .dropdown {
                .dropdown-menu {
                    left: 0 !important;
                    right: auto !important;
                }
            }
        }
        &__logo {
            padding-right: 5px;
            padding-left: 0;
            &--text {
                padding-right: 13px;
                padding-left: 0;
            }
        }
        &__info {
            &--serach {
                /deep/ {
                    .form-control {
                        padding-right: 55px;
                        padding-left: 30px;
                        text-align: right;
                    }
                    button {
                        right: 15px;
                        left: auto;
                        &.close {
                            left: 10px;
                            right: auto;
                        }
                    }
                }
            }
            ul {
                li {
                    &:not(:last-child) {
                        margin-left: rem(18px);
                        margin-right: 0;
                    }
                    a {
                        span {
                            left: 12px;
                            right: auto;
                            @media screen and (max-width: 1500px) {
                                left: 6px;
                            }
                            @media screen and (max-width: 991px) {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
