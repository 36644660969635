import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'

import { i18n } from '@/common/i18n'
import urlReg from '@/common/url_service'

// vee validate rules
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule])
})

extend('url', {
    validate: (value) => {
        return urlReg.validURL(value)
    },
    message: i18n.t('general.Url is not valid'),
})

extend('english', {
    validate: (value) => {
        const en_only = /^[A-Za-z]+$/
        return en_only.test(value)
    },
    message: i18n.t('general.English letters only'),
})
const requireComponent = require.context(
    './base',
    false,
    /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )
    Vue.component(componentName, componentConfig.default || componentConfig)
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
