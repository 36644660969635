<template>
    <div class="form-group">
        <validation-observer>
            <div>
                <validation-provider
                    :name="name"
                    v-slot="{ errors, passed }"
                    :rules="rules"
                >
                    <input
                        :placeholder="placeholder"
                        :value="value"
                        class="form-control"
                        :type="type"
                        :class="[
                            errors.length && !bordered
                                ? `error-border-bottom`
                                : errors.length && bordered
                                ? `error-border`
                                : className,
                            passed && !readonly && !bordered
                                ? `success-border-bottom`
                                : passed && bordered
                                ? `success-border`
                                : className,
                            readonly ? `readonly` : className,
                        ]"
                        @input="$emit('input', $event.target.value)"
                    />
                    <span
                        class="invalid-feedback"
                        :class="absolute ? 'position-absolute' : ''"
                        v-if="errors.length"
                        >{{ errors[0] }}</span
                    >
                </validation-provider>
            </div>
        </validation-observer>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        placeholder: String,
        value: [String, Number],
        required: Boolean,
        readonly: Boolean,
        type: String,
        className: String,
        loginInterface: Boolean,
        bordered: Boolean,
        absolute: {
            default: false,
            type: Boolean,
        },
        confirmPassword: {
            default: false,
            type: Boolean,
        },
        rules: String,
    },
}
</script>
<style lang="scss" scoped>
/* Form Validation Classes */
.error {
    color: red !important;
}
.error-border-bottom,
.error-border-bottom:hover,
.error-border-bottom:focus {
    border-bottom: 1px solid red !important;
}
.error-border,
.error-border:hover,
.error-border:focus {
    border: 1px solid red !important;
}
.success-border-bottom,
.success-border-bottom:hover,
.success-border-bottom:focus {
    border-bottom: 1px solid green !important;
}
.success-border,
.success-border:hover,
.success-border:focus {
    border: 1px solid green !important;
}
.success {
    color: green !important;
}
.invalid-feedback {
    display: block;
}
</style>
