<template>
    <div class="menu">
        <div class="flex-wrapper">
            <div class="menu__logo">
                <img
                    src="@/assets/images/svg/logo.svg"
                    alt=""
                    class="img-fluid"
                />
                <div v-if="userDetails" class="menu__logo--text">
                    <p>
                        {{ userDetails.full_name }}
                    </p>
                    <p>
                        {{ userDetails.email }}
                    </p>
                </div>
            </div>
            <span class="menu__close">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.505"
                    height="24.505"
                    viewBox="0 0 24.505 24.505"
                >
                    <path
                        d="M12.311,11.2l9.856,9.855a.787.787,0,1,1-1.114,1.113L11.2,12.311,1.343,22.166A.787.787,0,0,1,.231,21.053L10.086,11.2.231,1.344A.787.787,0,0,1,1.343.231L11.2,10.085,21.053.231a.787.787,0,1,1,1.114,1.113Z"
                        transform="translate(1 1.108)"
                        fill="#292f78"
                        stroke="#292f78"
                        stroke-width="2"
                    />
                </svg>
            </span>
        </div>
        <ul>
            <li
                class="menu__item"
                :class="
                    $route.matched.some(
                        ({ name }) => name === 'dashboard_index'
                    )
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/dashboard`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <img
                            src="@/assets/images/svg/dashboard-icon.svg"
                            alt=""
                        />
                        <img
                            class="active"
                            src="@/assets/images/svg/dashboard-active.svg"
                            alt=""
                        />
                    </div>
                    {{ $t('general.Dashboard') }}
                </router-link>
            </li>
            <li
                v-if="userAuth && userAuth.is_superuser"
                class="menu__item"
                :class="
                    $route.matched.some(({ name }) => name === 'vendor')
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/vendors`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <img src="@/assets/images/svg/vendor-icon.svg" alt="" />
                        <img
                            class="active"
                            src="@/assets/images/svg/vendor-active.svg"
                            alt=""
                        />
                    </div>
                    {{ $t('vendors.vendors') }}
                </router-link>
            </li>
            <li
                v-if="userAuth && userAuth.is_superuser"
                class="menu__item"
                :class="
                    $route.matched.some(
                        ({ name }) => name === 'vendor_applications'
                    )
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/vendor-applications`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <img src="@/assets/images/svg/application.svg" alt="" />
                        <img
                            class="active"
                            src="@/assets/images/svg/application-active.svg"
                            alt=""
                        />
                    </div>
                    {{ $t('vendors.Applications') }}
                </router-link>
            </li>
            <li
                class="menu__item"
                :class="
                    $route.matched.some(
                        ({ name }) => name === 'transaction_index'
                    )
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/transactions`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <img src="@/assets/images/svg/transaction.svg" alt="" />
                        <img
                            class="active"
                            src="@/assets/images/svg/transaction-active.svg"
                            alt=""
                        />
                    </div>
                    {{ $t('general.Transactions') }}
                </router-link>
            </li>
            <li
                class="menu__item"
                :class="
                    $route.matched.some(({ name }) => name === 'orders')
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/orders`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <div class="menu__item--icon">
                            <img src="@/assets/images/svg/orders.svg" alt="" />
                            <img
                                class="active"
                                src="@/assets/images/svg/orders-active.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    {{ $t('general.Orders') }}
                </router-link>
            </li>
            <li
                class="menu__item"
                :class="
                    $route.matched.some(
                        ({ name }) => name === 'customers_index'
                    )
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/customers`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <div class="menu__item--icon">
                            <img
                                src="@/assets/images/svg/customer.svg"
                                alt=""
                            />
                            <img
                                class="active"
                                src="@/assets/images/svg/customer-active.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    {{ $t('general.Customers') }}
                </router-link>
            </li>
            <li
                class="menu__item"
                :class="
                    $route.matched.some(({ name }) => name === 'profile')
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/profile`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <div class="menu__item--icon">
                            <img src="@/assets/images/svg/profile.svg" alt="" />
                            <img
                                class="active"
                                src="@/assets/images/svg/profile-active.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    {{ $t('general.Profile') }}
                </router-link>
            </li>
            <!--            <li-->
            <!--                class="menu__item"-->
            <!--                :class="-->
            <!--                    $route.matched.some(-->
            <!--                        ({ name }) => name === 'notification_index'-->
            <!--                    )-->
            <!--                        ? 'active'-->
            <!--                        : ''-->
            <!--                "-->
            <!--            >-->
            <!--                <router-link-->
            <!--                    :to="`/${$i18n.locale}/notifications`"-->
            <!--                    class="menu__item&#45;&#45;link"-->
            <!--                >-->
            <!--                    <div class="menu__item&#45;&#45;icon">-->
            <!--                        <div class="menu__item&#45;&#45;icon">-->
            <!--                            <img-->
            <!--                                src="@/assets/images/svg/notification-icon.svg"-->
            <!--                                alt=""-->
            <!--                            />-->
            <!--                            <img-->
            <!--                                class="active"-->
            <!--                                src="@/assets/images/svg/notification-active.svg"-->
            <!--                                alt=""-->
            <!--                            />-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    {{ $t('general.Notifications') }}-->
            <!--                </router-link>-->
            <!--            </li>-->
            <li
                v-if="userAuth && userAuth.is_superuser"
                class="menu__item"
                :class="
                    $route.matched.some(({ name }) => name === 'home_edit')
                        ? 'active'
                        : ''
                "
            >
                <router-link
                    :to="`/${$i18n.locale}/home/edit`"
                    class="menu__item--link"
                >
                    <div class="menu__item--icon">
                        <img src="@/assets/images/home.png" alt="" />
                        <img
                            class="active"
                            src="@/assets/images/home-active.png"
                            alt="home-page"
                        />
                    </div>
                    {{ $t('general.Home Page') }}
                </router-link>
            </li>
            <li class="menu__item">
                <a href="javascript:void(0)" @click="logout">
                    <div class="menu__item--icon">
                        <div class="menu__item--icon">
                            <img src="@/assets/images/svg/logout.svg" alt="" />
                            <img
                                class="active"
                                src="@/assets/images/svg/logout-active.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    {{ $t('auth.Logout') }}
                </a>
            </li>
        </ul>
        <div class="copyRight">
            © {{ new Date().getFullYear() }} BuyTech
            <p class="hide-on-lg">Copy Right for BuyTech website</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { UsersHelper } from '@/common/crud-helpers/users'

export default {
    props: {
        userDetails: Object,
    },
    mounted() {
        let button = document.querySelectorAll('.menu__close')
        let menuLink = document.querySelectorAll('.menu__item--link')
        let body = document.querySelector('body')
        let html = document.querySelector('html')
        button.forEach((closebtn) => {
            closebtn.addEventListener('click', () =>
                body.classList.remove('menu-active')
            )
            closebtn.addEventListener('click', () =>
                html.classList.remove('no-scroll')
            )
        })
        menuLink.forEach((link) => {
            link.addEventListener('click', () =>
                body.classList.remove('menu-active')
            )
            link.addEventListener('click', () =>
                html.classList.remove('no-scroll')
            )
        })
    },
    computed: {
        ...mapGetters(['userAuth']),
    },
    methods: {
        async logout(e) {
            e.preventDefault()
            await UsersHelper.userLogout().then(() => {
                this.$router.push({
                    name: 'login',
                    params: {
                        lang: this.$i18n.locale,
                    },
                })
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: rem(22px);
    &__logo {
        display: flex;
        align-items: center;
        img {
            height: 50px;
        }
        &--text {
            padding-left: 13px;
            p {
                color: #0e1446;
                line-height: 1;
                margin: 0;
                font-size: rem(20px);
                font-weight: 600;
                font-family: $primary-font;
                &:last-child {
                    font-family: $reboto-font;
                    font-size: rem(14px);
                    font-weight: 500;
                    margin-top: 5px;
                }
            }
        }
    }
    .flex-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            display: inline-block;
            cursor: pointer;
        }
    }
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: rem(50px);
        padding-top: rem(50px);
    }
    &__item {
        position: relative;
        width: 42%;
        transition: 0.4s ease all;
        $self: &;
        &:not(:last-child) {
            margin-bottom: rem(45px);
        }
        a {
            color: rgba($color: #0c0524, $alpha: 0.5);
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 1400px) and (min-width: 1200px) {
                font-size: 13px;
            }
        }
        &--icon {
            width: rem(75px);
            height: rem(75px);
            color: #65d3ff;
            background: rgba($color: #bdecff, $alpha: 0.25);
            @include flex(center, center);
            border-radius: 15px;
            margin: auto;
            margin-bottom: rem(20px);
            position: relative;
            overflow: hidden;
            transition: 0.2s ease;
            &:before {
                display: none;
            }
            img {
                &.active {
                    display: none;
                }
            }
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                a {
                    color: #0c0524;
                }
                #{$self}--icon {
                    color: #fff;
                    background-color: var(--primary);
                    img {
                        display: none;
                        &.active {
                            display: block;
                        }
                    }
                }
            }
        }
        &.active {
            a {
                color: #0c0524;
            }
            #{$self}--icon {
                color: #fff;
                background-color: var(--primary);
                img {
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
    .copyRight {
        font-size: rem(18px);
        font-family: $primary-font;
        font-weight: 500;
        color: var(--primary);
        @media screen and (max-width: 991px) {
            text-align: center;
            font-size: rem(12px);
            padding: rem(15px) 0 0 0;
            color: var(--primary);
            font-family: $reboto-font;
        }
        p {
            font-size: rem(14px);
            font-family: $reboto-font;
            margin: 13px 0 0 0;
            line-height: 1.2;
            color: #809fb8;
            font-weight: 400;
        }
    }
    @media screen and (min-width: 992px) {
        .flex-wrapper {
            display: none;
        }
    }
    @media screen and (max-width: 991px) {
        justify-content: start;
        &__item {
            border-bottom: 1px solid rgba($color: #7594ac, $alpha: 0.1);
            width: 50%;
            padding-top: rem(30px);
            padding-bottom: rem(28px);
            margin-bottom: 0 !important;
            &--icon {
                width: rem(93px);
                height: rem(93px);
            }
            &:nth-child(odd) {
                border-right: 1px solid rgba($color: #7594ac, $alpha: 0.1);
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
        ul {
            overflow-y: scroll;
            padding-bottom: 100px;
        }
    }
}

[lang='ar'] {
    .menu {
        &__logo {
            &--text {
                padding-right: 13px;
                padding-left: 0;
            }
        }
        @media screen and (max-width: 991px) {
            &__item {
                &:nth-child(odd) {
                    border-left: 1px solid rgba($color: #7594ac, $alpha: 0.1);
                    border-right: 0;
                }
            }
        }
    }
}
</style>
