import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/modules/auth/router'
import dashboard from '@/modules/dashboard/router'
import home from '@/modules/dashboard/router/home.js'
import notifications from '@/modules/notifications/router'
import orders from '@/modules/orders/router'
import order_approve from '@/modules/orders/router/approve.js'
import products from '@/modules/products/router'
import transactions from '@/modules/transactions/router'
import users from '@/modules/users/router'
import vendors from '@/modules/vendors/router'
Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...login,
        ...dashboard,
        ...home,
        ...transactions,
        ...products,
        ...users,
        ...orders,
        ...order_approve,
        ...notifications,
        ...vendors,
    ],
})
