<template>
    <!-- <div class="pagination">
		<div class="count">Showing 1 to 13 of 50 elements</div>
		<div>
			<b-pagination-nav
				:link-gen="linkGen"
				:number-of-pages="30"
				next-text="Next"
				prev-text="Prev"
				align="center"
				use-router
			></b-pagination-nav>
		</div>
	</div> -->
    <div class="pagination-wrapper">
        <div class="count">Showing 1 to 13 of 50 elements</div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            next-text="Next"
            prev-text="Prev"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    props: {
        info: String,
    },
    data() {
        return {}
    },
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? '?' : `?page=${pageNum}`
        },
    },
}
</script>

<style lang="scss" scoped>
.pagination {
    margin-top: rem(24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .count {
        font-size: rem(16px);
        color: #7074a6;
        font-weight: 400;
        font-family: $reboto-font;
    }
    /deep/ {
        .pagination {
            padding: 5px 0;
            .page-item {
                height: 40px;
                width: 40px;
                @include flex(center, center);
                margin-bottom: 0;
                &.disabled:not(.bv-d-xs-down-none) {
                    display: none;
                }
                &:first-child,
                &:last-child {
                    display: none;
                }
                &:nth-child(2) {
                    width: 100px;
                    height: 40px;
                    border: 1px solid #e4e6e8;
                    border-radius: 10px;
                    position: relative;
                    margin-right: rem(20px);
                    span,
                    a {
                        color: #7074a6;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.166' height='10.869' viewBox='0 0 6.166 10.869'%3E%3Cpath id='Form_1' data-name='Form 1' d='M5.435,4.306,9.544.226a.78.78,0,0,1,1.1,0,.767.767,0,0,1,0,1.09L5.984,5.941a.78.78,0,0,1-1.1,0L.227,1.316a.767.767,0,0,1,0-1.09.781.781,0,0,1,1.1,0Z' transform='translate(0 10.87) rotate(-90)' fill='%23809fb8'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        width: 7px;
                        height: 12px;
                        top: 50%;
                        transform: translateY(-50%) rotate(180deg);
                        left: 8px;
                        right: auto;
                    }
                }
                &:nth-last-child(2) {
                    border: 1px solid #e4e6e8;
                    border-radius: 10px;
                    position: relative;
                    margin-left: rem(20px);
                    width: 100px;
                    height: 40px;
                    a {
                        color: #7074a6;
                    }
                    span {
                        font-size: 0;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.166' height='10.869' viewBox='0 0 6.166 10.869'%3E%3Cpath id='Form_1' data-name='Form 1' d='M5.435,4.306,9.544.226a.78.78,0,0,1,1.1,0,.767.767,0,0,1,0,1.09L5.984,5.941a.78.78,0,0,1-1.1,0L.227,1.316a.767.767,0,0,1,0-1.09.781.781,0,0,1,1.1,0Z' transform='translate(0 10.87) rotate(-90)' fill='%23809fb8'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        width: 6px;
                        height: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 8px;
                        left: auto;
                    }
                }
                .page-link {
                    background: transparent;
                    color: #171721;
                    font-family: $reboto-font;
                    font-weight: 500;
                    font-size: rem(15px);
                    padding: 0 6px;
                    margin: 0px;
                    border: 0;
                    outline: none !important;
                    box-shadow: none !important;
                    display: block;
                    @include flex(center, center);
                    height: 100%;
                    width: 100%;
                    &.page-link {
                        outline: none;
                    }
                }
                &.active {
                    a {
                        color: #fff;
                        background: #3e7eff;
                        border-radius: 8px;
                        border: 1px solid #3e7eff;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        /deep/ {
            .pagination {
                .page-item {
                    width: 28px;
                    height: 28px;
                    &:nth-child(2) {
                        width: 66px;
                        height: 30px;
                        border-radius: 6px;
                        margin-right: rem(16px);
                    }
                    &:nth-last-child(2) {
                        width: 66px;
                        height: 30px;
                        border-radius: 6px;
                        margin-left: rem(16px);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 667px) {
        flex-direction: column;
        .count {
            margin-bottom: rem(16px);
        }
    }
}
</style>
