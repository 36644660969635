import { i18n } from '@/common/i18n'
import formatter from '@/plugins/usd-format.js'
const TableHandler = {
    setUserTable(customersList) {
        customersList.results.map((customer) => {
            customer.isActive = true
            customer._cellVariants = { name: 'customer' }
            customer._showDetails = false
            customer.date = this.setReadableDate(customer.date_joined)
            customer.full_name =
                (customer[`first_name_${i18n.locale}`]
                    ? customer[`first_name_${i18n.locale}`]
                    : customer.first_name_en) +
                ' ' +
                (customer[`last_name_${i18n.locale}`]
                    ? customer[`last_name_${i18n.locale}`]
                    : customer.last_name_en)
            customer.governorate_name = customer.governorate
                ? customer.governorate
                : '-'
            customer.img = customer.profile_pic
                ? customer.profile_pic
                : require('@/assets/images/customer-image.png')
            customer.active = customer.is_active
                ? `${i18n.t('general.Active')}`
                : `${i18n.t('general.Not Active')}`
        })
        return customersList
    },
    setOrdersTable(ordersList) {
        ordersList.results.map((order, index) => {
            order.isActive = true
            order._cellVariants = { name: 'customer_full_name' }
            order.name = order.customer_full_name
            order._showDetails = false
            order.date = this.setReadableDate(order.created)
            order.remaining_installments = order.remaining_no_of_installments
            order.amount = formatter.makeItReadable(order.total, 'iqd')
            order.id = index + 1
            order.customer_img = order.customer_profile_pic
                ? order.customer_profile_pic
                : require('@/assets/images/customer-image.png')
            order.order_status = `${i18n.t(`orders.${order.order_status}`)}`
        })
        return ordersList
    },
    setTransactionsTable(transactionsList) {
        transactionsList.results.map((transaction) => {
            transaction.isActive = true
            transaction._cellVariants = { name: 'customer' }
            transaction._showDetails = false
            transaction.date = this.setReadableDate(transaction.created)
            transaction.full_name =
                (transaction[`customer_first_name_${i18n.locale}`]
                    ? transaction[`customer_first_name_${i18n.locale}`]
                    : transaction.customer_first_name_en) +
                ' ' +
                (transaction[`customer_last_name_${i18n.locale}`]
                    ? transaction[`customer_last_name_${i18n.locale}`]
                    : transaction.customer_last_name_en)
            transaction.amount = formatter.makeItReadable(
                transaction.amount,
                'iqd'
            )
            transaction.governorate = transaction.customer_address_state
                ? transaction.customer_address_state
                : '-'
            transaction.order_no = transaction.order_no
                ? transaction.order_no
                : `${i18n.t('general.Field is empty')}`
            transaction.notes = Object.keys(transaction.associate_order).length
                ? transaction.associate_order.notes
                : `${i18n.t('general.Field is empty')}`
            transaction.order_id = Object.keys(transaction.associate_order)
                .length
                ? transaction.associate_order.order_number
                : ''
            transaction.customer_img = transaction.customer_img
                ? transaction.customer_img
                : require('@/assets/images/customer-image.png')
            transaction.state = `${i18n.t(`transactions.${transaction.state}`)}`
        })
        return transactionsList
    },
    setVendorsTable(vendorsList) {
        vendorsList.results.map((vendor) => {
            vendor.isActive = true
            vendor._cellVariants = { name: 'customer' }
            vendor._showDetails = false
            vendor.full_name = vendor[`first_name_${i18n.locale}`]
                ? vendor[`first_name_${i18n.locale}`]
                : vendor.first_name_en +
                  ' ' +
                  vendor[`last_name_${i18n.locale}`]
                ? vendor[`last_name_${i18n.locale}`]
                : vendor.last_name_en
            vendor.full_name = vendor.first_name + ' ' + vendor.last_name
            vendor.vendor_id = vendor.id
            vendor.profile_pic = vendor.profile_pic ? vendor.profile_pic : ''
            vendor.total_orders = vendor.orders
                ? vendor.orders.length
                : `${i18n.t('general.Field is empty')}`
            vendor.total_revenue = vendor.all_revenue
                ? vendor.all_revenue
                : `${i18n.t('general.Field is empty')}`
            vendor.date = this.setReadableDate(vendor.date_joined)
            vendor.active = vendor.is_active
                ? `${i18n.t('general.Active')}`
                : `${i18n.t('general.Not Active')}`
            vendor.governorate = vendor.governorate ? vendor.governorate : '-'
        })
        return vendorsList
    },
    setApplicationsTable(applicationsList) {
        applicationsList.results.map((application) => {
            application.isActive = true
            application._cellVariants = { name: 'customer' }
            application._showDetails = false
            application.full_name =
                application.first_name + ' ' + application.last_name
            application.application_id = application.id
            application.approved = application.is_approve
                ? `${i18n.t('general.Approved')}`
                : `${i18n.t('general.Not Approved')}`
        })
        return applicationsList
    },
    setInstallmentsTable(installmentsList) {
        installmentsList.results.map((installment) => {
            installment.isActive = true
            installment._cellVariants = { name: 'customer' }
            installment._showDetails = false
            installment.installment_date = this.setReadableDate(
                installment.date_of_installment
            )
            installment.amount = formatter.makeItReadable(
                installment.amount,
                'usd'
            )
            installment.number = installment.installment_number
            installment.paid = installment.is_paid ? 'Paid' : 'Not Paid'
        })
        return installmentsList
    },
    setReadableDate(date) {
        let item_date = new Date(date)
        let day = String(item_date.getDate()).padStart(2, '0')
        let month = String(item_date.getMonth() + 1).padStart(2, '0')
        let year = item_date.getFullYear()

        item_date = day + '/' + month + '/' + year
        return item_date
    },
}

export default TableHandler
